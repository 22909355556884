<template>
    <div>
        <!-- @ok="saveData"-->
        <b-modal id="modal-names" ref="namesmodal" title="Manage Player Names" ok-title="Save" @ok="saveData" :okDisabled="okbuttondisabled" @shown="modelIsOpen" @hide="modelIsClose" @cancel="modelIsCancel">
            <p class="mt-4 mb-0">If you accidently name your friendly player with 2 different name, you need to fix it so the ranking get correct. Drag-drop players that are the same person:</p>
            
            <div class="flex-container">
                <div  class="namecontainer ">
                    <div class="draggable-list" >
                        <div  class="list-item"> Ted </div>
                        <div  class="list-item"> Teddy </div>
                    </div>
                </div>
            </div>
            <p class="mt-1 mb-3">Example above, Join in "Ted" with "Teddy" and the system regard this as One player. Then Save. </p>


            <b-button v-on:click="getNames()" variant="primary" size="sm" class="mx-1">GetPlayers</b-button>

            
            <div  v-if="namesArrayofArrays.length>0" class="flex-container">
                <div v-for="(namesArray, index) in namesArrayofArrays" :key="index" class="namecontainer ">
                    <draggable class="draggable-list" :list="namesArray"  :move="(event) => onMove(event)" group="namings2">
                        <div v-for="(names, i) in namesArray" :key="i" class="list-item">
                            <b-icon v-if="index===0" icon="trash" variant="danger"></b-icon>
                            {{ names }}                             
                        </div>
                    </draggable>
                </div>
            </div>            
            
          
    
        </b-modal>
    </div>
</template>
     

<script>
import RestService from '@/services/RestService.js'
import draggable from "vuedraggable";

export default {
    components: {
        draggable,
    },
    name: "names",


    data() {
        return {
            okbuttondisabled:true,
            namesArrayofArrays:[],
            samenameplayers:[[]],
            
        };
    },
    methods: {
        showRankForGroup(key) {
            this.selected = key
            this.rankedplayers = this.groups[key]
        },
        saveData() {            
            //console.log("OK - Will save" +this.nameDuplicated)
            this.saveSameNamePlayers(this.nameDuplicated)
            this.deleteClubPlayers(this.filterOutScrapData(this.namesArrayofArrays[0]))
            this.namesArrayofArrays = []
            this.okbuttondisabled = true;        
        },
        filterOutScrapData(arrayOfNames) {           
            if (arrayOfNames.includes("DELETE THIS")) {
                let elementPos2 = arrayOfNames.indexOf("DELETE THIS")                                
                arrayOfNames.splice(elementPos2, 1);
            }
            return arrayOfNames;
        },

        async deleteClubPlayers(listOfPlayers) {
            console.log("Will delete this club players" +listOfPlayers)            
            try {
                let mytoken = this.$cookies.get('lop-jwt');                
                let response = await RestService.deleteClubplayers(listOfPlayers, mytoken)     
                console.log("deleteClubPlayers resp = " +response)                                           
            } catch (error) {
                console.log("Can not deleteClubPlayers")
            }
        },


        async saveSameNamePlayers(sameNamePlayers) {
            try {
                let mytoken = this.$cookies.get('lop-jwt');                
                await RestService.putSameNamePlayers(sameNamePlayers, mytoken)   
            } catch (error) {
                console.log("Can not putSameNamePlayers")
            }
        },

        async getNames() {
            this.loading = true;
            try {
                let mytoken = this.$cookies.get('lop-jwt');                
                let responseMyNames = await RestService.getPlayers(mytoken)                                
                if (typeof responseMyNames.data.samenameplayers !== 'undefined')          {
                    this.samenameplayers=responseMyNames.data.samenameplayers;
                }
                this.populateNamingObject(responseMyNames.data.players, this.samenameplayers)                                
            } catch (error) {
                console.log("Can not load Rankings")
            }
            this.loading = false;    
            this.okbuttondisabled = false;        
        },
        //onMove(event) {
        onMove() {            
            let lastIndex = this.namesArrayofArrays.length -1
            if ( (typeof this.namesArrayofArrays[lastIndex] !== 'undefined' )) {                
                let lastElemenet = this.namesArrayofArrays[lastIndex];
                if (lastElemenet.length > 0) {
                    this.namesArrayofArrays.push([])
                }
            }   
            setTimeout(this.moveDelete, 2000)    

        },

        moveDelete() {
            if (typeof this.namesArrayofArrays[0] == 'undefined') {
                return ;
            }
            let elementPos = this.namesArrayofArrays[0].indexOf("DELETE THIS")
            if (elementPos == -1) {
                this.namesArrayofArrays[0].unshift("DELETE THIS")
            }
            else if (elementPos > 0) {
                this.namesArrayofArrays[0].splice(elementPos, 1);
                this.namesArrayofArrays[0].unshift("DELETE THIS")                
            } 
            for (let i=1; i<this.namesArrayofArrays.length; i++) {                
                if (this.namesArrayofArrays[i].includes("DELETE THIS")) {
                    let elementPos2 = this.namesArrayofArrays[i].indexOf("DELETE THIS")
                    this.namesArrayofArrays[i].splice(elementPos2, 1);
                }
            }
        },

        modelIsOpen() {
            //console.log("MODAL OPEENNE")
            this.namesArrayofArrays = []
            this.okbuttondisabled = true;        
        },
        modelIsClose() {
            //console.log("MODAL CLOSING")
        },
        modelIsCancel() {
            //console.log("MODAL CANCEL")
            this.namesArrayofArrays = []
            this.okbuttondisabled = true;        
        },


        populateNamingObject(arrayOfNames, samePlayerArrayArray) {            
            let names2 = []
            if (typeof arrayOfNames !== 'undefined')
                for (let i = 0; i < arrayOfNames.length; i++) {     
                    let sameNameCheck = this.isNameInSameName(arrayOfNames[i],samePlayerArrayArray)
                    let myInsertArray = []
                    if ( sameNameCheck.position == -1 ) {
                        myInsertArray.push(arrayOfNames[i])                        
                    } else if (sameNameCheck.position == 0) {
                        myInsertArray = JSON.parse(JSON.stringify(sameNameCheck.names))
                    } 
                    if (myInsertArray.length > 0) {
                        names2.push(myInsertArray)
                    }                    
                }
            names2.push([])            
            names2.sort(this.compare);   

            names2.unshift(["DELETE THIS"])        
            this.namesArrayofArrays = names2;
        },
        compare(a,b) {
            let aleng = a.length
            let bleng = b.length
            if (aleng>0 && bleng>0) {
                let aTest = a[0]
                let bTest = b[0]
                return aTest.localeCompare(bTest)                 
            }
            return 0
        },


        isNameInSameName(name, sameNameArrayArray ) {
            //-1 notInList
            //0 in firstPos
            //n position
            //o={position, ArrayOfNames}
            let o = {}
            o['position'] = -1
            o['names'] = []
            
            for (let i=0; i<sameNameArrayArray.length; i++ ) {
                let nameNameArray = sameNameArrayArray[i]
                for (let k=0; k<nameNameArray.length; k++) {
                    if (name === nameNameArray[k]) {
                        o['position'] = k
                        o['names'] = sameNameArrayArray[i]
                        return o
                    }
                }
            }
            return o
        },
    },


    props: {
        email: String,


    },
    computed: {
        nameDuplicated: function() {            
            let result = []
            for (let i=1; i<this.namesArrayofArrays.length; i++) {
                if (this.namesArrayofArrays[i].length>1) {
                    result.push(this.namesArrayofArrays[i])
                }
            }            
            return result
        }



    },
    mounted() {
        console.log("MOUNTING NAMES")
    },

    watch: {
        // namesArrayofArrays: {
        //     deep: true,
        //     handler (value, oldValue) {                
        //         console.log('new value' + value)
        //         console.log('OLD value' + oldValue)                
        //     }
        // }
    },
};
</script>

<style scoped>
.flex-container {
    /*background: palegoldenrod; */
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    list-style: none;
    /*border: 2px solid black;*/
    
    /*align-items:stretch;*/
}

.namecontainer {    
    /*background: plum;    */
    border: 2px dashed black;
    margin: 0.3rem;
    padding: 0.3rem;    
    border-radius: 5px;
}

.draggable-list {
    /*border: 1px solid blue; */
    /*background: green;        */
    min-height: 2rem;
    height: 100%;
    min-width: 3rem;
}

.list-item {
    margin: 0.2rem;
    padding: 0.2rem;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
    background:whitesmoke;
    text-align: center;        
}

</style>

