import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    // baseURL: 'http://localhost:8080',

    //in dev
    //baseURL: 'http://localhost:8080',
    // in prod
    //baseURL: 'http://localhost:1880',
    withCredentials:false,
    headers: {
        Accept:'application/json',
        'content-type': 'application/json',            
    }
})

export default {
    getTournament(tournamnetid) {
        console.log("getTournament and tournamnetid=" +tournamnetid);
        return apiClient.get('/tournament',{
            params: {
                tournamnetid: tournamnetid,
            },
          })
    },
    getRoundForTournament(tournamnetid, roundindex) {
        console.log("getRoundForTournament. tournamnetid=" +tournamnetid +"  roundindex="+roundindex);
        return apiClient.get('/tournament',{
            params: {
                tournamnetid: tournamnetid,
                roundindex: roundindex,
            },
        })
    },
    getGenericFromTournament(tournamnetid, myfields) {
        console.log("getGenericFromTournament. tournamnetid=" +tournamnetid +"  fields="+JSON.stringify(myfields));
        return apiClient.get('/fieldfromtournament',{
            params: {
                tournamnetid: tournamnetid,
                fields: myfields,
            },
        })
        .catch((error) => {
            console.log("Problem connection to Server.")
            console.log(error)
        })
    },

    postTournament(tournament) {
        console.log("RestService.postTournament()...")
        return apiClient.post('/tournament',{                   
            tournament:tournament
        })
        .catch((error) => {
            console.log("Problem connection to Server.")
            console.log(error)
        })
    },
    putTournament(tournamnetid, rounds, roundindex) {
        console.log("RestService.putTournament()...")
        return apiClient.put('/tournament',{            
            tournamnetid:tournamnetid,
            rounds: rounds,
            roundindex: roundindex
        })
    },

    putGenericOnTournament(tournamnetid, updateObject) {
        console.log("RestService.putGenericOnTournament()...")
        let myObj = {}
        myObj["tournamnetid"] = tournamnetid;
        myObj["data"] = updateObject
        return apiClient.put('/tournamentgeneric',myObj)
    },
    popRoundinTournament(tournamnetid, updateObject) {
        console.log("RestService.popRoundinTournament()...")
        let myObj = {}
        myObj["tournamnetid"] = tournamnetid;
        myObj["data"] = updateObject
        return apiClient.put('/tournamentgeneric',myObj)
    },


    putNewRound(tournamnetid, round, roundindex) {
        console.log("RestService.putNewRound()...")
        return apiClient.put('/tournamentround',{            
            tournamnetid:tournamnetid,
            round: round,
            roundindex: roundindex
        })
    },

    postNewUser(user) {
        console.log("RestService.postNewUser()...")
        return apiClient.post('/user',{            
            user: user
        })
    },
    updatePasswordForUser(user, code) {
        console.log("RestService.postNewUser()...")
        return apiClient.post('/user',{            
            user: user, 
            code: code,
        })
    },

    login(user) {
        console.log("RestService.login()...")
        return apiClient.post('/login',{            
            user: user
        })
    },
    postNewTour(tour, token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.postNewTour()...")
        return apiClient.post('/tour',{            
            tour: tour
        },options)
    },
    putTourRound(tourround, tourRoundPlayersHash, tourid, token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.putTourRound()...")
        return apiClient.put('/tour',{            
            tourround: tourround,
            tourplayershash: tourRoundPlayersHash,
            tourid: tourid,
        },options)
    },


    getLopuser(token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.postNewTour()...")
        return apiClient.get('/lopuser',options)
    },

    getTours(token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.getTours()...")
        return apiClient.get('/gettours',options)
    },
    deleteTour(token, tourid) {
        const options = {
            headers: {'Authorization': 'Bearer '+token, 'Access-Control-Allow-Methods': 'DELETE'}
        };
        console.log("RestService.deleteTour()...")
        console.log("DELTET tourid()...= " + tourid)        
        return apiClient.put('/deletetour', 
        {'tourid': tourid},options
        )
    },

    deleteTournament(token, tournamentid) {
        const options = {
            headers: {'Authorization': 'Bearer '+token, 'Access-Control-Allow-Methods': 'DELETE'}
        };
        console.log("RestService.deleteTournament()...")
        console.log("DELTET tournamentid()...= " + tournamentid)        
        return apiClient.put('/deletetournament', 
        {'tournamentid': tournamentid},options
        )
    },



  




    getRank(token, historyTime) {
        const options = {
            headers: {'Authorization': 'Bearer '+token},
            params: {time: historyTime}
        };
        console.log("RestService.getRank()...")
        return apiClient.get('/getranking',options)
    },
    getGameshistory(token, historyTime) {
        const options = {
            headers: {'Authorization': 'Bearer '+token},
            params: {time: historyTime}
        };
        console.log("RestService.getGameshistory()...")
        return apiClient.get('/gameshistory',options)
    },
    getPlayers(token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.getPlayers()...")
        return apiClient.get('/getplayers',options)
    },
    putPlayers(players, token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.putTourRound()...")
        return apiClient.put('/players',{            
            players: players            
        },options)
    },
    
    deleteClubplayers(players, token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.deleteClubplayers()...")
        return apiClient.put('/deleteclubplayers',{            
            players: players            
        },options)
    },


    putSameNamePlayers(sameNamePlayers, token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
        };
        console.log("RestService.putSameNamePlayers()...")
        return apiClient.put('/updatesamenameplayers',{            
            sameNamePlayers: sameNamePlayers            
        },options)
    },



    getpwdreset(email) {
        console.log("getpwdreset and email=" +email);
        return apiClient.get('/pwdreset',{
            params: {
                email: email,
            },
          })
    },
    getemailfromcode(code) {
        console.log("getemailfromcode and code=" +code);
        return apiClient.get('/getemailfromcode',{
            params: {
                code: code,
            },
          })
    },

  


    
      
      
    getTest(token) {
        const options = {
            headers: {'Authorization': 'Bearer '+token}
          };
        console.log("RestService.getTest()...")
        return apiClient.get('/test',options)
    },

    // after completeRound. Send updated players object with latest score.
    completePlayersScore(tournamnetid, players) {
        console.log("RestService.completePlayersScore()... ")
        return apiClient.put('/tournamentplayers',{            
            tournamnetid:tournamnetid,
            players: players            
        })
    },

    completeRound(tournamnetid, round, roundindex, isupdate) {
        console.log("RestService.completeRound()... roundindex="+roundindex)
        return apiClient.put('/completeround',{            
            tournamnetid:tournamnetid,
            round: round,
            roundindex: roundindex,
            isupdate: isupdate
        })
    },
}