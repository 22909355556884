import Vue from "vue"

//const socket = new WebSocket("wss://echo.websocket.org")
//const socket = new WebSocket("ws://137.184.122.167:1880/ws/simple")
// wss://api.lopadel.se
//const socket = new WebSocket("wss://api.lopadel.se/ws/simple")
//working prod 24 mars +const socket = new WebSocket("wss://137.184.122.167:1880/ws/simple")

let fullwsUrl = process.env.VUE_APP_WSS_ROOT + '/ws/simple'
const socket = new WebSocket(fullwsUrl);

const emitter = new Vue({
    methods: {
        send(message) {
            let consoleMess = "";
            if (message.length > 100) {
                consoleMess = message.substring(0, 99) + "...";
            } else {
                consoleMess = message;
            }
            console.log("IN WebsocketService. Send MESSAGE:"+consoleMess)
            if (1 === socket.readyState)
                socket.send(message)
        }
    }
})

socket.onmessage = function (msg) {
    console.log("OnMessage. Receiving a message")
    let msgAsString = JSON.stringify(msg.data)
    let consoleMess = "";
    if (msgAsString.length > 100) {
        consoleMess = msgAsString.substring(0, 99) + "...";
    } else {
        consoleMess = msgAsString;
    }
    console.log("-R E C E I V E    M E S S A G E-")
    console.log("IN WebsocketService.onmessage msg:"+consoleMess)    
    emitter.$emit("message", msg.data)
}


socket.onerror = function (err) {
    console.log("OnError")
    emitter.$emit("error", err)
}
socket.onclose = function(event) {
    console.log("onClose" +event.code + "   reason" + event.reason)
}

socket.onopen = function(event)  {
    console.log("WebSocket connection opened:", event);
};

export default emitter