<template>
  <div id="finalboard-table">
   
    <h1>{{ title }}</h1>    
    <table class="table" v-bind:class="myclassObject">
      <thead>
        <th>#</th>
        <th>Groupscore</th>
        <th>Name</th>
        <th>Points</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id" >
          <td>  {{index+1}} <b-badge v-bind:style="tablerowbackground(index)">{{courtname(index)}}</b-badge> </td>    
          <td class="mymuted">({{ item.totalscore }} p)</td>
          <td>{{ item.name }}
            <b-icon-info-circle v-if="item.earlyleaver>0" variant="danger" v-b-tooltip.hover="'Early leaver. Got default points after the ' +item.earlyleaver +' round'">                 
            </b-icon-info-circle> 
          </td>
          <td v-if="(typeof item.final !== 'undefined') && (item.final.score > -1)">
            {{ item.final.score }}
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "finalboard-table",
  data() {
    return {};
  },
  props: {
    items: Array,
    title: String,
    courts:Array,
    playerspercourt:Number,
  },
  mounted() {},
  methods: {
    //this.playerspercourt
    tablerowbackground: function (index) {            
      let myCourt = Math.floor(index / this.playerspercourt)      
      let presentedCourtsInUI_num = this.presentedCourtsInUI();
      if (myCourt >= presentedCourtsInUI_num) {
        return {'background-color':'lightgrey', 'color':'black'}        
      }
      let myColor = this.courts[myCourt].color    
      return {'background-color':myColor, 'color':'black'}      
    },
    courtname : function(index) {      
      let presentedCourtsInUI_num = this.presentedCourtsInUI();
      let pos = Math.floor(index / this.playerspercourt)      
      if (pos >= presentedCourtsInUI_num) {
        return "-"
      }
      return this.courts[pos].name      
    },
    presentedCourtsInUI() {
      let activePlayers = this.items.filter(myItem => {
        return (typeof myItem.earlyleaver === 'undefined')
      })      
      let activePlayserFillUpThisNumbersOfCourts = Math.floor(activePlayers.length / this.playerspercourt)
      return Math.min(activePlayserFillUpThisNumbersOfCourts, this.courts.length)       
    },
  },
  computed: {    
    myclassObject: function () {
      if (this.playerspercourt == 2) {
        return {
          'secondlinemargin': true,        
        }
      } else if (this.playerspercourt == 4) {
        return {
          'forthlinemargin': true,        
        }
      } else {
        return {};
      }
    }
    
  },
};
</script>

  // table tr:nth-child(4n) {    
  //   border-bottom-width : 1rem;    
  // }

<style scoped>
  .mymuted {
    font-style: italic;
    color:gray;   
  }
  

  .secondlinemargin tr:nth-child(2n) {    
    border-bottom-width : 1rem;    
  }
  .forthlinemargin tr:nth-child(4n) {    
    border-bottom-width : 1rem;    
  }
  
</style>