

// https://codepen.io/David_Hutt/pen/jjOjMZ 
<template>
    <div>
        <b-card-group columns>
            <b-card class="cardfrontpage" bg-variant="transparant">
                <b-card-body class="text-center">
                    <b-card-title class="cardtitlefrontpage">Play Americano</b-card-title>
                    <b-card-text>
                        Start play Americano. 4-43 players. All player play with, and against all other players, if you have time to play enough rounds. You can add "late arrivers" at any point. This competition will appoint a true winner! 
                    </b-card-text>
                    <b-button variant="primary"  @click="startplayamericano">Start Play Americano</b-button>                               
                </b-card-body>
            </b-card>

            <b-card class="cardfrontpage">
                <b-card-body class="text-center">
                    <b-card-title class="cardtitlefrontpage">Play Mexicano</b-card-title>
                    <b-card-text>
                        Start play Mexicano. 4-43 players. Players are matched to play with those on same rank. A vinner level up to next rank. This tournament flats out skill level and give more even matches.
                    </b-card-text>
                    <b-button variant="primary"  @click="startplaymexicano">Start Play Mexicano</b-button>                               
                </b-card-body>
            </b-card>

            
            
            <b-card class="cardfrontpage" v-if="feature.includes('teams')">
                <b-card-body class="text-center">
                    <b-card-title class="cardtitlefrontpage">Play Americano Teams</b-card-title>
                    <b-card-text>
                        Start play Americano Teams. 4-21 teams. Partners are played together during the tournament, but shuffle combatants.
                    </b-card-text>
                    <b-button variant="primary"  @click="startplayamericanoteams">Start Play Americano Teams</b-button>                               
                </b-card-body>
            </b-card>
            

            <b-card class="cardfrontpage" v-if="feature.includes('tour')">
                <b-card-body class="text-center">
                    <b-card-title class="cardtitlefrontpage">Play TOUR Americano</b-card-title>
                    <b-card-text>
                        Have a number of Americano games collected in a Tour, collecting points over a set Americano events. You can add new players to the Tour whenever you want
                    </b-card-text>
                    <b-button variant="primary"  @click="startplaytour">Start Play Tour Americano</b-button>                               
                </b-card-body>
            </b-card>


            <b-card class="cardfrontpage">
                <b-card-body class="text-center">
                    <b-card-title class="cardtitlefrontpage">LoPadel Features</b-card-title>
                    <b-card-text align="left">
                        <b-list-group flush>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check> <span>Any number of players between 4 and 43</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check> <span>Any number of courts between 1 and 10</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check> <span>NO app-download. Just a website <b>lopadel.se</b></span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>Guarantee all players play equally many rounds, independent of how many you are</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>Americano, Americano-Teams or Mexicano</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>Quick and andvanced gaming engine - All players team up with all, and fight against all</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>Let "Late arrival player" join at any time</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>Allow "Early leaver player" leave when they want</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>Add, or remove a court during your tournament</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>In game, switch between Americano and Mexicano, if Rookie/PRO player kill the fun</span></b-list-group-item>
                            <b-list-group-item><b-icon-check font-scale="2" shift-v="-2" style="color: green;margin-right:1rem"> </b-icon-check><span>FAST - no lagging - no waiting</span></b-list-group-item>
                        </b-list-group>        
                    </b-card-text>
                </b-card-body>
            </b-card>
        </b-card-group>
    
    </div>
</template>
     

<script>
export default {
    name: "static-info",

    data() {
        return {
            slide: 0,
            sliding: null,
            slide2: 0,
            sliding2: null,
        };
    },
    methods: {
        onSlideStart() {
            this.sliding = true;
            //console.log("on slide start. slide = " + slide);
        },
        onSlideEnd() {
            this.sliding = false;
            //console.log("on slide end. slide = " + slide);
        },
        startplayamericano() {
            console.log("IN START startplayamericano")                
            this.$emit("startplay", "americano");
        },
        startplaymexicano() {            
            this.$emit("startplay", "mexicano");
        },
        startplayamericanoteams() {            
            this.$emit("startplay", "americanoteam");
        },
        startplaytour() {            
            this.$emit("startplay", "americano", "tour");
        },                


    },
    props: {
        feature:Array,
    },
    computed: {
    },
    mounted () {        

    },

    watch: {},
};
</script>

<style scoped>

.cardtitlefrontpage {    
  font-family: "Permanent Marker", sans-serif;
  color: rgba(110, 100, 6, 0.959);
}
.cardfrontpage {
    /* background: linear-gradient(65deg,#d6deee, #edf0f0 );   */

    /* background: linear-gradient(65deg, green, red);   */
    /* background-color: none; */
    border: 9px solid rgba(110, 100, 6, 0.959);;
    margin: 2rem;
    border-radius: 0.7rem;
    max-width: 900px;
}

.list-group-item {
    display: flex;
    padding: 0.2rem 0.4rem;
}

</style>