<template>
    <div>
        <h3 > <i>{{extraLabelText}}</i> {{tourname}} </h3>
        <b-table striped hover :items="tourPlayerItems" :fields="fields">
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>
           <template #cell(tourscore)="data">
                <i v-for="(aValue, index) in data.value" :key="index">
                    {{aValue}} 
                </i>
            </template>
        </b-table>       


        <b-container fluid>
        <b-row class="my-1">
            <b-col sm="9">
            <label for="input-none">Number rounds not counting:</label>
            </b-col>
            <b-col sm="3">
            <b-form-input v-model="notCountingRounds" type="number" :max='maxCountToNotCount' min=0 />            
            </b-col>
        </b-row>
        </b-container>         
         
    </div>
</template>

<script>
export default {
  name: "tourstandings-table",
  data() {
     return {        
        fields: [{'index':'#'}, {'name':'Player'},{'tourscore':'RoundScores'} ,{'totaltourscore': 'Tourscore'}],
        notCountingRounds:0,
      };
  },
  props: {
    tourObject:Object,
    extraLabelText:String,
  },
  
  methods: {
    getAggreageStanding(tourRounds) {
        if (typeof tourRounds === 'undefined') {
            return [];
        }
        console.log("getAggreageStanding");
        let tourPlayerHash = {};
        for (let i=0; i<tourRounds.length; i++) {            
            for (let j=0; j<tourRounds[i].tourplayers.length; j++) {
                let tourplayer = tourRounds[i].tourplayers[j];
                if (typeof tourPlayerHash[tourplayer.id] === 'undefined') {
                    tourPlayerHash[tourplayer.id] = {};
                    tourPlayerHash[tourplayer.id].name = tourplayer.name
                    tourPlayerHash[tourplayer.id].tourscore = []
                    for (let k=0; k<i; k++) {
                        tourPlayerHash[tourplayer.id].tourscore[k]=0
                    }
                    tourPlayerHash[tourplayer.id].totaltourscore = 0;
                }
                
                if (typeof tourplayer.tourscore !== 'undefined' && tourplayer.tourscore > 0) {
                    tourPlayerHash[tourplayer.id].tourscore[i] = tourplayer.tourscore                    
                } else {
                    tourPlayerHash[tourplayer.id].tourscore[i] = 0;                    
                }                
                tourPlayerHash[tourplayer.id].totaltourscore += tourplayer.tourscore;
            }
            let allTourPlayersID = Object.keys(tourPlayerHash)

            for (let k=0; k< allTourPlayersID.length; k++) {
                let playerstourScore = tourPlayerHash[allTourPlayersID[k]].tourscore
                if (Array.isArray(playerstourScore) ) {
                    if (playerstourScore.length < (i+1)) {                        
                        tourPlayerHash[allTourPlayersID[k]].tourscore[i] = 0
                    }
                }
            }
            //console.log("ready with tour standings")

        }
        let tourPlayersItems = Object.values(tourPlayerHash)
        if (this.notCountingRounds>0 && this.notCountingRounds<=this.maxCountToNotCount) {
            this.removeTheWorstRounds(this.notCountingRounds,tourPlayersItems)
        }        
        tourPlayersItems = tourPlayersItems.sort(function (a, b) {
            if (a.totaltourscore == b.totaltourscore) {
                let aMax = Math.max.apply(null, a.tourscore);
                let bMax = Math.max.apply(null, b.tourscore);
                return (bMax-aMax);
            } 
            return b.totaltourscore - a.totaltourscore;            
        });
        console.log("tourPlayersItem lenght =" +tourPlayersItems.length)                
        return tourPlayersItems;
    },
    removeTheWorstRounds(nbrToRemove, tourPlayersItemTemp) {        
        console.log("nbrToRemove" +nbrToRemove)
        for (let i=0; i<tourPlayersItemTemp.length; i++) {
            let tourscoreTemp = tourPlayersItemTemp[i].tourscore;
            if (nbrToRemove >= tourscoreTemp.length) {
                nbrToRemove = tourscoreTemp.length-1                
            }
            if (tourscoreTemp.length > nbrToRemove) {
                tourPlayersItemTemp[i].tourscore = this.removeSmallestM(tourscoreTemp,  nbrToRemove)
                tourPlayersItemTemp[i].totaltourscore = this.sumOfArray(tourPlayersItemTemp[i].tourscore)
            }
        }
        return tourPlayersItemTemp
    },
    removeSmallestM(arr, M) {        
        let N = arr.length;
        let A = [...arr];
        A.sort(function (a, b) { return a - b; })
        let mp = new Map();
        for (let i = 0; i < M; i++) {
            mp.set(A[i], 1);
        }
        let results = []
        let countRemoved = 0;
        for (let i = 0; i < N; i++) {
            if (countRemoved>=M) {
                results.push(arr[i])                
            }
            else if (mp.has(arr[i]) == false ) {
                results.push(arr[i])                
            } else {
                countRemoved ++;
            }
        }
        return results;
    },
    sumOfArray(arrayOfNumbers) {
        let sum = 0;
        for (let i = 0; i < arrayOfNumbers.length; i++) {
            sum += arrayOfNumbers[i];
        }
        return sum;
    },
  },
  computed: {
    tourname() {
        return this.tourObject.tourname
    },
    tourPlayerItems() {
        return this.getAggreageStanding(this.tourObject.tourrounds) 
    },
    maxCountToNotCount() {
        console.log(this.tourObject.tourrounds.length);
        return this.tourObject.tourrounds.length-1
    }
  },
  mounted () {

  },

  
  watch: {
  },

};
</script>

<style scoped>

td {
  padding: 0.2rem;
}
</style>