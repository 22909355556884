<template>
  <div id="register-item">

        
    <b-form @submit="handleSubmit">

      <b-form-group>
        <b-form-input                    
          v-model="item.name"
          ref="first"
          autocomplete="off"
          :placeholder="defaltPlaceholder"
          :invalid-feedback="invalidFeedback"     
          :state="state"         
          :list="listName"      
        ></b-form-input>
        <b-form-datalist v-if="(istour || true) && label==='player'" :id="listName" :options="playersoptionlist"></b-form-datalist>
        

        <b-form-invalid-feedback :state="state">
            {{invalidFeedback}} 
        </b-form-invalid-feedback>
        

        <b-form-input                    
          v-if="this.type === 'americanoteam' "
          v-model="item.name_pairmate"          
          autocomplete="off"
          :placeholder="defaltPlaceholder_pairmate"
          :invalid-feedback="invalidFeedback2"     
          :state="state_pairmate"  
          class="extrainputfield"             
        ></b-form-input>


        <b-form-invalid-feedback v-if="!state_pairmate" :state="state_pairmate">
            {{invalidFeedback2}} 
        </b-form-invalid-feedback>

      </b-form-group> 

      <div class="buttoncontainer">
        <b-button block :disabled="buttondisabled" class="americanobutton" type="submit" variant="primary">Add {{ buttonlabel }}</b-button>
        <b-button block v-if="showAddAllTourPlayersButton" @click="addAllTourPlayers" class="americanobutton rightbutton" variant="outline-primary">Add all {{getToruplayersCount}} Tourplayers</b-button>
      </div>
    </b-form>

    <b-alert
      :show="dismissCountDown"
      variant="danger"      
      @dismiss-count-down="countDownChanged"
    >     Oooops, can not add item. Identical name!
    </b-alert>



  </div>
</template>

<script>
export default {
  name: "register-court",
  data() {
    return {
      item: {
        name: "",
        name_pairmate:"",
      },
      maxcourts:10,
      maxplayers:43,                


      dismissSecs: 2,
      dismissCountDown: 0,      
    };
  },
  props: {
    label: String, 
    buttonlabel:String,
    courtslength:Number,
    playerslength:Number,
    type:String,
    istour:Boolean,
    playersoptionlist:Array,    
    showCanNotAddItem:Boolean,
  },
  methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },


    handleSubmit(event) {
      event.preventDefault()
      if (this.label == "court" && (this.item.name.length <3 || this.item.name.length >11) ) {
          this.item.name = "court "+(this.courtslength+1)
          //return;
      } else if (this.label == "player" && ( this.item.name.length < 3 || this.item.name.length>11)  ){      
        return;
      }
      //this.$emit("add:item", this.item.name);
      this.$emit("add:item", this.properName);      
      this.item.name = "";
      this.item.name_pairmate = "";
      this.$refs.first.focus();
    },
    getProperFeedbackMessage(stringlength, issecondline) {      
      if (this.label === "court" && issecondline) {
        return "";
      }
      if (this.label === "player" && this.type !== 'americanoteam' && issecondline) {
        return "";
      }

      if (this.label === "court" && this.courtslength > (this.maxcourts-1)) {
        return "Ooops - Can not add more courts. " + this.maxcourts + " is max";
      }
      if (this.label === "player" && this.playerslength > (this.maxplayers-1)) {
        return "Ooops - Can not add more players. "+this.maxplayers +" is max";
      }

      let playerstring = "player"
      if (this.type === 'americanoteam') {
        playerstring = "teams"
      }
      if ( stringlength > 11 ) {
        return "Ooops, to looong now!";
      }
      if (stringlength > 0 && stringlength < 3) {
        return "Enter at least 3 charachters!";
      }
      if (this.label == "court" && this.courtslength == 0 ) {
        return "You need to add at least 1 court";
      }
      if (this.label == "player" && this.playerslength <4) {
        if (this.type === 'americanoteam' && !issecondline) {        
          //not return 
        } else {
          return "You need to add at least 4 " +playerstring;
        }
      }
      return "Please enter something.";
    },
    getProperState(stringlength) {      
      if (this.label == "court" && this.courtslength == 0) {
        return false;
      }
      if (this.label == "court" && this.courtslength > (this.maxcourts-1)) {
        return false;
      }
      if (this.label === "player" && this.playerslength > (this.maxplayers-1)) {
        return false;
      }

      if (this.label == "player" && this.playerslength < 4) {
        if (stringlength > 11) {
          return false;  
        } 
        if (stringlength > 2) {
          return true;  
        } 
        return false;
      }
      if (stringlength == 0 ) {
        return true;
      }
      if (stringlength > 11 ) {
        return false;
      }
      return stringlength >= 3;      
    },
    addAllTourPlayers() {
      console.log("Will add aLL tourplayers")
      this.$emit("addSetOfPlayers");            
    },
   
  },
  computed: {
    listName() {
      return "input-list2"+this.label
    },
    getToruplayersCount() {
      if (Array.isArray(this.playersoptionlist) &&  this.playersoptionlist.length>0) 
        return this.playersoptionlist.length
      else
        return "";
    },
    showAddAllTourPlayersButton() {
      if (this.label == "player") {
        if (this.istour && Array.isArray(this.playersoptionlist) &&  this.playersoptionlist.length>0)  {
          return true;
        }
      }
      return false;
    },
    defaltPlaceholder() {
      console.log("calcualte defaltPlaceholder")
      if (this.label == "player") {
        return "James"
      } else if (this.label == "court") {
        return "court " +(this.courtslength+1)
      } else {
        return ""
      }      
    },
    defaltPlaceholder_pairmate() {
      return "Jonna"
    },
    buttondisabled() {
      if (this.label === "court" && this.courtslength > (this.maxcourts-1)) {
        return true;
      }
      if (this.label === "player" && this.playerslength >(this.maxplayers-1) ) {
        return true;
      }

      if (this.label == "player" && (this.item.name.length <3 || this.item.name.length >11) ) {
        return true;
      }
      if (this.type === 'americanoteam') {
        if (this.label == "player" && (this.item.name_pairmate.length <3 || this.item.name_pairmate.length >11) ) {
          return true;
        }
      }
      if (this.label == "court" && this.item.name.length >11) {
        return true;
      }
      return false;
      
    },
    state() {      
      return this.getProperState(this.item.name.length)
    },
    state_pairmate() {
      return this.getProperState(this.item.name_pairmate.length)    
    },
    invalidFeedback() {
      let feedback = this.getProperFeedbackMessage(this.item.name.length, false)
      return feedback
      
    },
    invalidFeedback2() {              
      return this.getProperFeedbackMessage(this.item.name_pairmate.length, true)
    },
    properName() {
      if (this.type === 'americanoteam') {
        return this.item.name.trim() + " & "+this.item.name_pairmate.trim()
      } else {
        return this.item.name.trim()
      }
      
    }
  },

  watch: { 
    showCanNotAddItem: function(newVal, oldVal) { 
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (!oldVal && newVal) {
        console.log("chened to bad name");
        this.dismissCountDown = this.dismissSecs;
      }
    }
  }

};
</script>

<style scoped>

/* .mybutton {
  margin: 1rem 0 0.5rem 0;
} */

.extrainputfield {
  margin-top: 0.5rem;
}
</style>