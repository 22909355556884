<template>
    <div class="rootamericano">
        <b-alert variant="danger" dismissible fade :show="showCanNotLoadTournamnet" @dismissed="showCanNotLoadTournamnet=false">
            Sorry, can not load the tournament
        </b-alert>
    
        <div v-if="this.VUE_APP_USEEXPERIMENTAL">
            Experimental: VUE_APP_USEEXPERIMENTAL
            <br> lopuser= {{lopuser}}
        </div>
    
    
    
    
    
    
        <div>
            <b-navbar v-if="this.VUE_APP_USEAMERICANOTOUR" type="dark" variant="dark" class="navbarflex">
                <b-navbar-nav>
                    <b-nav-item-dropdown text="More Features" right>
                        <b-dropdown-item v-on:click="openSelectTourModal()" :disabled="!isloggedin">CreateNew/Select Tour</b-dropdown-item>
                        <b-dropdown-item v-on:click="openRankModal()" :disabled="!isloggedin">Player Rank</b-dropdown-item>
                        <b-dropdown-item v-on:click="openGamesHistoryModal()" :disabled="!isloggedin">Games History</b-dropdown-item>
                        <b-dropdown-item v-on:click="openNamesModal()" :disabled="!isloggedin">Manage player Names</b-dropdown-item>
                        <b-dropdown-item v-on:click="openextras()">Fortune wheel</b-dropdown-item>
                        <b-dropdown-item v-if="isloggedin" v-on:click="openlogoutmodal()">LogOut</b-dropdown-item>
                        <b-dropdown-item v-if="!isloggedin" v-on:click="openloginmodal()">LogIn</b-dropdown-item>
                    </b-nav-item-dropdown>
    
                </b-navbar-nav>
    
    
                <b-navbar-nav class="navbarflex">
                    <b-nav-item-dropdown :text="getnavbarusername">
                        <b-dropdown-item v-if="isloggedin" v-on:click="openlogoutmodal()">LogOut</b-dropdown-item>
                        <b-dropdown-item v-else v-on:click="openloginmodal()">LogIn</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item v-if="isloggedin">
                        <b-icon icon="unlock-fill" variant="success"></b-icon>
                    </b-nav-item>
                    <b-nav-item v-else>
                        <b-icon icon="lock-fill" variant="danger"></b-icon>
                    </b-nav-item>
    
    
    
                </b-navbar-nav>
    
    
    
            </b-navbar>
        </div>
    
    
    
        <div class="headercontainer">
            <div class="headerone">
                <h1>LoPadel Americano</h1>
                <h6 v-if="this.mytournament.name.length>0">hosting {{this.mytournament.type}}</h6>
                <h4>{{this.mytournament.name}}</h4>
                <div v-if="this.mytournament.isactive && this.tournamenthasinit" class="currentroundcontainer">
                    <div class="currentroundlabel"> <b>Round</b> </div>
                    <div class="currentroundnumber" :style="currentrounddisplaysize">
                        <b v-if="isfinalplay">Fin</b>
                        <b v-else>{{currentroundindex+1}}</b>
                    </div>
                    <div class="currenttime"> {{getFormattedDate(currentroundindex)}} </div>
                </div>
            </div>
    
            <div v-show="showfriendscanreport" class="headertwo">
    
                <div v-if="user.type === 'master'" class="editbutton">
                    <b-button variant="primary" id="tooltip-target-1" v-show="showTournamentAsSideBar" v-b-toggle.sidebar-1>
                        Edit
                        <b-icon-pencil animation="cylon"> </b-icon-pencil>
                    </b-button>
                    <b-tooltip target="tooltip-target-1" triggers="hover" placement="auto">
                        Edit your Tournament, like add <b>"late arrivals"</b> or remove <b>"early leavers"</b>
                    </b-tooltip>
                </div>
    
                <b-card v-show="this.currentid !== 'undefined' && this.currentid!=''">
                    <b-card-img :src="'https://api.qrserver.com/v1/create-qr-code/?data=' +this.appurl+ '/?tournamentid='+this.currentid +'&amp;size=150x150'" alt="Image" class="rounded-0" style="max-width: 250px;"></b-card-img>
                    <b-card-text class="text-center">
                        <h5>JOIN!</h5>
                    </b-card-text>
                </b-card>
            </div>
    
    
    
        </div>
    
    
        <div v-if="!this.mytournament.isactive">
            <b-collapse id="collapse-1" v-model="showintropage" class="mt-2">
                <static-info :feature="feature" @startplay="startplay" />
            </b-collapse>
        </div>
    
    
        <register-user :registerUserMode="registerUserMode" :renewPasswordExistingEmail="renewPasswordExistingEmail" :renewPasswordtemporaryCode="renewPasswordtemporaryCode" :isloggedin="isloggedin" @switchtype="switchtype" @add:lopuser="setLopuser" @userloggedinEmit="userloggedinEmit"
        />
    
        <register-tour v-if="isloggedin" :email="this.lopuser" :tournamentisstarted="this.mytournament.isactive" @selectedtour="setselectedtour" />
    
        <rank :email="this.lopuser" />
        <gameshistory :email="this.lopuser" />
        <names :email="this.lopuser" />
    
        <extras :players="activePlayersList" />
    
    
        <div>
            <b-sidebar id="sidebar-1" width="90%" title="Edit Tournament" shadow>
                <div class="px-3 py-2">
                    <register-tournament v-bind:tournament="mytournament" viewtype="main" :screenwidth="window.width" :colors="colors" :feature="feature" :playersoptionlist="playersoptionlist" :tourplayersHash="selectedtour.tourplayershash" @add:tournament="addTournament"
                        @cancel:tournament="newtournament" @earlyLeaver:player="putPlayers" @comingBack:player="putPlayers" @latearrival:player="putPlayers" @recalculateSort="recalculateSort" />
                </div>
            </b-sidebar>
        </div>
    
        <register-tournament v-if="!showTournamentAsSideBar" v-bind:tournament="mytournament" viewtype="sidebar" :validForTour="isloggedin" :colors="colors" :feature="feature" :playersoptionlist="playersoptionlist" :tourplayersHash="selectedtour.tourplayershash"
            :screenwidth="window.width" :selectedTour="selectedtour.tourname" @add:tournament="addTournament" @openloginmodal="openloginmodal" @openSelectTourModal="openSelectTourModal" />
    
        <round-display :displayreactor="rounddisplayreactor" :round="mytournament.rounds[currentroundindex]" :roundindex="currentroundindex" :allscorereported="isAllMatchesInCurrentRoundReported" :width="window.width" :usertype="user.type" :iswaitingforDB="iswaitingforDB"
            :showPositionAndTrend="true" :showCompleteRoundbutton="mytournament.showgroundplayLeaderboard" @report:points="updatematchresult" @completeround="completeround" @completefinal="completefinal" @cancelfinal="cancelfinal" @cancelgroupplay="cancelgroupplay"
            v-if="tournamenthasinit" />
    
        <finalboard-table v-bind:items="filterFinalplayers()" :playerspercourt="mytournament.playerspercourt" :title="finalLeaderBoardString" :courts="mytournament.courts" v-if="isfinalplay" />
    
        <leaderboard-table v-bind:items="mytournament.players" :defaultnumberofvisiblecolumns="numberOfVisibleColumns" :sortby="mytournament.sortbymethod" :title="groupPlayLeaderBoardString" :displayreactor="rounddisplayreactor" v-if="mytournament.showgroundplayLeaderboard && this.mytournament.isactive"
            @newScore="newScore" />
        <tourstandings-table v-if="savedToAmericaTour" :tourObject="selectedtour" extraLabelText="Tourstandings for: " />
    
        <div v-if="isgroupplay">
            <div v-if="mytournament.showgroundplayLeaderboard || this.mytournament.rounds[this.currentroundindex].iscomplete" class="buttoncontainer">
                <b-button v-on:click="drawFinal()" :disabled="!mytournament.rounds[currentroundindex].iscomplete || iswaitingforDB" variant="primary" class="leftbutton">
                    Play Final Round
                </b-button>
                <b-button v-on:click="drawGroup()" :disabled="!mytournament.rounds[currentroundindex].iscomplete || iswaitingforDB" variant="primary" class="rightbutton">
                    Play another Group Round
                </b-button>
            </div>
    
            <div v-else class="buttoncontainer">
                <b-button v-on:click="completeAndDrawFinal()" :disabled="disablePressCompleteRoundAndDraw" variant="primary" class="rightbutton">
                    Play Final Round
                </b-button>
                <b-button v-on:click="completeAndDrawGroup()" :disabled="disablePressCompleteRoundAndDraw" variant="primary" class="rightbutton">
                    Play another Group Round
                </b-button>
            </div>
    
        </div>
    
    
    
        <div class="buttoncontainer" v-if="user.type === 'master' && isfinalplay">
            <b-button v-on:click="newtournament" variant="primary" class="leftbutton">
                New Tournament
            </b-button>
    
            <b-button v-if="VUE_APP_USEAMERICANOTOUR && mytournament.istour" v-on:click="saveTour" v-b-toggle.collapse-2 :disabled="disableSaveToTour" variant="primary" class="rightbutton">
                Save to Americano Tour
            </b-button>
        </div>
    
        <div>
            <template>
                <div style="margin-top:1rem;">
                  <b-form-checkbox v-model="mytournament.showgroundplayLeaderboard" name="check-button" switch>            
                    <span  style="margin-left:0.5rem;">Show LeaderBoard</span>            
                  </b-form-checkbox>
                </div>
</template>
    </div>
    <div style="margin-top:1rem;font-style: italic; color: blue;font-size: 0.8em;" >
       Feedback, support and tutorial. 
      <a href="https://www.facebook.com/lopadel.2023" target="_blank"><b-icon icon="facebook" variant=""></b-icon> lopadel.se</a> on facebook (opens new tab)
    </div>

  </div>
</template>

<script>
import RoundDisplay from "./components/RoundDisplay.vue";
import RegisterTournament from "./components/RegisterTournament.vue";
import LeaderboardTable from "./components/LeaderboardTable.vue";
import FinalboardTable from "./components/FinalboardTable.vue";
import TourstandingsTable from "./components/TourstandingsTable.vue";
import RestService from '@/services/RestService.js'
import LogicService from '@/services/LogicService.js'
import Socket from '@/services/WebsocketService.js'
import StaticInfo from "./components/StaticInfo.vue"
// import RegisterLogin from "./components/RegisterLogin.vue"
import RegisterUser from "./components/RegisterUser.vue"
import RegisterTour from "./components/RegisterTour.vue"
import Rank from "./components/Rank.vue"
import Gameshistory from "./components/Gameshistory.vue"
import Names from "./components/Names.vue"
import Extras from './components/Extras.vue'

import VueCookies from 'vue-cookies'

//import uniqueId from "lodash.uniqueid";

export default {
    name: "App",
    components: {
        RoundDisplay,
        RegisterTournament,
        LeaderboardTable,
        FinalboardTable,
        TourstandingsTable,
        StaticInfo,
        RegisterUser,
        RegisterTour,
        Rank,
        Gameshistory,
        Names,
        Extras,
    },

    data() {
        return {
            mytournament: {
                name: "",
                type: "americano",
                istour: false,
                points: 21,
                maxscore: 21,
                maxscorefinal: 24,
                sortbymethod: "points",
                finaltype: "one_three",
                finaldynamicscore: true,
                courts: [],
                players: [],
                allowplayertoreport: "true",
                id: "",
                rounds: [{}],
                showgroundplayLeaderboard: true,
                polling: null,
            },
            user: {
                type: 'slave',
            },
            colors: ["#89c2c2", "#dcf2ae", "#E7D1E3", "#aef2e4", "#c2bb89", "#b7aef2", "#f2aeae", "#f7d088", "#bddb7c", "#F5E4FB"],
            groupPlayLeaderBoardString: "Group round",
            finalLeaderBoardString: "Final",
            rounddisplayreactor: 0,
            window: {
                width: 0,
                height: 0,
            },
            axiosinfo: null,
            axiosloading: true,
            axioserrored: false,
            currentid: "",
            currentroundindex: 0,
            currentroundstart: "",
            showCanNotLoadTournamnet: false,
            latearrivalgetdefaultpoints: 0.7,
            appurl: "",
            iswaitingforDB: false,
            invalidTournamentTime: 800,
            showintropage: true,
            registerUserMode: "login",
            renewPasswordExistingEmail: "",
            renewPasswordtemporaryCode: "",
            VUE_APP_USEAMERICANOTOUR: false,
            VUE_APP_USEAMERICANOTEAMS: false,
            VUE_APP_USEPLAYERREPORT: false,
            VUE_APP_USEEXPERIMENTAL: false,
            lopuser: "",
            isloggedin: false,
            feature: [],
            selectedtour: {},
            playersoptionlist: [],
            savedToAmericaTour: false,

        };
    },
    methods: {
        pollData() {
            this.polling = setInterval(() => {
                let myPingMessage = { type: "ping" }
                if (typeof this.mytournament._id !== 'undefined') {
                    myPingMessage.tournamentid = this.mytournament._id
                }
                this.sendMess(JSON.stringify(myPingMessage))
            }, 50000)
        },

        startplay(type, istour) {
            console.log("start play in Mother type=" + type)
            console.log("start play in istour=" + istour)
            this.mytournament.type = type
            this.showintropage = false;
            if (istour === 'tour') {
                console.log("type == tour");
                //this.mytournament.istour = true
                // TODO
                if (!this.isloggedin) {
                    this.openloginmodal();
                } else {
                    this.openSelectTourModal();
                }
            } else {
                if (this.isloggedin) {
                    this.populateClubPlayers()
                }
            }
        },

        async addTournament(tournament) {
            this.showintropage = false;
            this.savedToAmericaTour = false;
            this.user.type = "master";
            this.mytournament = JSON.parse(JSON.stringify(tournament));
            for (let i = 0; i < this.mytournament.courts.length; i++) {
                this.mytournament.courts[i].color = this.colors[i]
            }
            if (tournament.type === 'americanoteam') {
                this.mytournament.playerspercourt = 2;
            } else {
                this.mytournament.playerspercourt = 4;
            }
            console.log("Will check on tour")
            //let isloggedinTemp = await this.isLoggedInDB();
            //console.log("Is logge di isloggedin= "+isloggedinTemp)    
            if (this.mytournament.istour && this.isLoggedInDB()) {
                console.log("it is Tour")
                this.mytournament.tourid = this.selectedtour._id;
            } else {
                this.mytournament.tourid = '';
            }
            //if (isloggedinTemp ) {
            if (this.isloggedin) {
                this.mytournament.email = this.lopuser
                this.putClubPlayers(this.filterOutNamesFromPlayers(this.mytournament.players))
            }
            this.mytournament.rounds = [];
            this.mytournament.finalplayers = [];
            this.mytournament.createat = new Date();
            this.mytournament.lastdraw = new Date();

            let response = await RestService.postTournament(this.mytournament);
            console.log("postTournament. id=" + response.data[0]);
            this.mytournament._id = response.data[0];
            this.currentid = response.data[0];
            this.sendMess(JSON.stringify({ "register": this.mytournament._id, "usertype": "master" }));
            this.drawGroup();
            localStorage.tournament = JSON.stringify(this.mytournament);
            localStorage.user = JSON.stringify(this.user);
        },
        newtournament() {
            localStorage.removeItem('tournament')
            localStorage.removeItem('user')
            console.log("newtournament")
            location.reload();
        },

        async drawFinal() {
            this.iswaitingforDB = true;
            console.log("Enter drawFinal()")
            let playersTemp = this.mytournament.players.filter(function(el) {
                return (!(typeof el.earlyleaver !== 'undefined'))
            });

            let round = LogicService.generateRound(this.mytournament.playerspercourt, this.mytournament.type, "finalplay", this.mytournament.finaltype, this.mytournament.courts, playersTemp, this.mytournament.rounds, this.mytournament.maxscorefinal, this.mytournament.sortbymethod)

            try {
                await RestService.putNewRound(this.mytournament._id, round, this.mytournament.rounds.length)
                let updateObj = {}
                updateObj['$set'] = { "finalplayers": round.activeplayers }
                await RestService.putGenericOnTournament(this.mytournament._id, updateObj)
                console.log("Has posted FinalPlayers to Mongo")
                this.mytournament.rounds.push(round);
                this.currentroundindex = this.mytournament.rounds.length - 1;
                this.currentroundstart = this.mytournament.rounds[this.currentroundindex].time
                console.log("Now, setting this.currentroundindex" + this.currentroundindex);
                this.sendMess(JSON.stringify({ type: "rounds", tournamentid: this.mytournament._id, roundindex: this.currentroundindex }))
                this.mytournament.finalplayers = round.activeplayers;
                localStorage.tournament = JSON.stringify(this.mytournament);
                this.iswaitingforDB = false;
                this.scrolltotop();
            } catch (error) {
                console.log("ERROR RestService.putNewRound")
                console.log("404. Can not putNewRound. Tournamnetid: " + this.mytournament._id + "  round:" + round.activeplayers)
            }
        },

        async drawGroup() {
            console.log("Enter drawGroup()")
            this.mytournament.lastdraw = new Date();
            this.iswaitingforDB = true;
            //ToDo filter out PAUSED players
            let playersTemp = this.mytournament.players.filter(function(el) {
                return (!(typeof el.earlyleaver !== 'undefined'))
            });
            let round = LogicService.generateRound(this.mytournament.playerspercourt, this.mytournament.type, "groupplay", this.mytournament.finaltype, this.mytournament.courts, playersTemp, this.mytournament.rounds, this.mytournament.maxscore, this.mytournament.sortbymethod)
            console.log("Clear forceIdle")
            for (let i = 0; i < this.mytournament.players.length; i++) {
                this.mytournament.players[i].forceidle = false
            }
            try {
                let response = await RestService.putNewRound(this.mytournament._id, round, this.mytournament.rounds.length)
                console.log("RestService.putNewRound()... response data:")
                console.log(response.data)
                this.mytournament.rounds.push(round);
                this.currentroundindex = this.mytournament.rounds.length - 1;
                this.currentroundstart = this.mytournament.rounds[this.currentroundindex].time
                console.log("Now, setting this.currentroundindex" + this.currentroundindex);
                this.sendMess(JSON.stringify({ type: "rounds", tournamentid: this.mytournament._id, roundindex: this.currentroundindex }))
                localStorage.tournament = JSON.stringify(this.mytournament);
                this.iswaitingforDB = false;
                this.scrolltotop();
            } catch (error) {
                console.log("ERROR RestService.putNewRound")
                console.log("404. Can not putNewRound. Tournamnetid: " + this.mytournament._id + "  round:" + round)
            }
        },
        async completeAndDrawGroup() {
            console.log("Enter completeAndDrawGroup()")
            await this.completeround(this.currentroundindex)
            await this.drawGroup()
        },
        async completeAndDrawFinal() {
            console.log("Enter completeAndDrawGroup()")
            await this.completeround(this.currentroundindex)
            await this.drawFinal()
        },

        // players in a tournament
        async putPlayers() {
            console.log("putPlayers()");
            if (this.isloggedin) {
                this.putClubPlayers(this.filterOutNamesFromPlayers(this.mytournament.players))
            }
            let updateObj = {}
            updateObj['$set'] = { "players": this.mytournament.players }
            try {
                await RestService.putGenericOnTournament(this.mytournament._id, updateObj)
                this.sendMess(JSON.stringify({ type: "players", tournamentid: this.mytournament._id }))
            } catch (error) {
                console.log(error);
            }
        },

        filterOutNamesFromPlayers(tournamentPlayers) {
            let simmplePlayers = []
            for (let i = 0; i < tournamentPlayers.length; i++) {
                simmplePlayers.push(tournamentPlayers[i].name)
            }
            return simmplePlayers;
        },
        // players relation to user
        async putClubPlayers(myPlayers) {
            console.log("putClubPlayers()");
            try {
                let mytoken = this.$cookies.get('lop-jwt');
                await RestService.putPlayers(myPlayers, mytoken)
            } catch (error) {
                console.log(error);
            }
        },

        scrolltotop() {
            window.scrollTo(0, 0);
        },
        scrolltobottom() {
            window.scrollTo(0, document.body.scrollHeight);
        },

        async completeround(roundindex) {
            console.log("Enter completeround()...")
            try {
                this.mytournament.rounds[roundindex].iscomplete = true;
                let isUpdate = false
                await RestService.completeRound(this.mytournament._id, this.mytournament.rounds[roundindex], roundindex, isUpdate);
                this.updateScore();
                await RestService.completePlayersScore(this.mytournament._id, this.mytournament.players)
                localStorage.tournament = JSON.stringify(this.mytournament);
                this.sendMess(JSON.stringify({ type: "rounds", tournamentid: this.mytournament._id, roundindex: roundindex }))
                this.scrolltobottom();
            } catch (error) {
                this.mytournament.rounds[roundindex].iscomplete = false;
                console.log("Round was already completed by another client. Wait for message to update")
                console.error(error);
            }
            console.log("... leaving completeround()")
        },



        async completefinal(roundindex) {
            console.log("completefinal()  roundindex=" + roundindex);
            try {
                this.mytournament.rounds[roundindex].iscomplete = true;
                let isUpdate = false
                await RestService.completeRound(this.mytournament._id, this.mytournament.rounds[roundindex], roundindex, isUpdate);
                this.updateScoreFinalPlayers(roundindex)
                localStorage.tournament = JSON.stringify(this.mytournament);
                this.sendMess(JSON.stringify({ type: "finalround", tournamentid: this.mytournament._id, roundindex: roundindex }))
            } catch (error) {
                this.mytournament.rounds[roundindex].iscomplete = true;
                console.error(error);
            }
        },

        async cancelfinal(roundindex) {
            console.log("In Cancel Final in APP.vue at roundinex=" + roundindex)
            this.mytournament.rounds.pop();
            let updateObj = {};
            updateObj['$pop'] = { rounds: 1 }
            await RestService.popRoundinTournament(this.mytournament._id, updateObj)
            this.currentroundindex = this.mytournament.rounds.length - 1
            this.sendMess(JSON.stringify({ type: "rounds", tournamentid: this.mytournament._id, roundindex: this.currentroundindex }))
            localStorage.tournament = JSON.stringify(this.mytournament);
        },

        async cancelgroupplay(roundindex) {
            console.log("In Cancel GroupPlay in APP.vue at roundinex=" + roundindex)
            this.mytournament.rounds.pop();
            let updateObj = {};
            updateObj['$pop'] = { rounds: 1 }
            await RestService.popRoundinTournament(this.mytournament._id, updateObj)
            this.currentroundindex = this.mytournament.rounds.length - 1
            this.sendMess(JSON.stringify({ type: "rounds", tournamentid: this.mytournament._id, roundindex: this.currentroundindex }))
            localStorage.tournament = JSON.stringify(this.mytournament);
        },

        updateFinalRoundForPlayers(playerId, finalScore) {
            this.mytournament.players.forEach((player) => {
                if (player.id === playerId) {
                    player.final = finalScore;
                }
            });
            this.mytournament.finalplayers.forEach((player) => {
                if (player.id === playerId) {
                    player.final = finalScore;
                }
            });
        },


        updatematchresult(matchid, teamid, points, roundindex, reportFurhter, forceSetMaxPoint) {
            if (reportFurhter) {
                //console.log("Will report furhter the matchresult")
                //todo . If changing old round point, we need to send the complete object again.
                this.sendMess(JSON.stringify({ type: "updatematchresult", tournamentid: this.mytournament._id, matchid: matchid, teamid: teamid, points: points, roundindex: roundindex }))
            }

            let gamepoints = this.mytournament.points;
            if (forceSetMaxPoint && forceSetMaxPoint > 0) {
                gamepoints = forceSetMaxPoint;
            }
            console.log("updatematchresult(). TeamId=" + teamid + "   and points= " + points + "   and roundindex" + roundindex + "   and matchid" + matchid);
            let currentRoundtype = this.mytournament.rounds[roundindex].type;
            this.mytournament.rounds[roundindex].matches.forEach((match) => {
                if (match.id === matchid) {
                    console.log("Updateing Matchresult. Found matchid=" + matchid + ". GREAT!!!");
                    match.teams.forEach((team) => {
                        if (team.id == teamid) {
                            team.score = points;
                            team.isreported = true;
                        } else if (this.mytournament.finaldynamicscore && currentRoundtype === 'finalplay') {
                            // no auto reporting of other team
                        } else {
                            team.score = gamepoints - points;
                            team.isreported = true;
                        }
                    });
                }
            });
            localStorage.tournament = JSON.stringify(this.mytournament);
            this.rounddisplayreactor = Math.floor(Math.random() * 1000000);
        },
        updateScoreFinalPlayers(roundindex) {
            let maxmatchIndex = -1
            this.mytournament.rounds[roundindex].matches.forEach((match, matchindex) => {
                maxmatchIndex = Math.max(matchindex, maxmatchIndex)
                match.teams.forEach((team) => {
                    let finalScore = { finalcourt: matchindex, score: team.score };
                    this.updateFinalRoundForPlayers(team.player1.id, finalScore);
                    console.log("this.mytournament.type=" + this.mytournament.type)
                    if (!(this.mytournament.type == 'americanoteam')) {
                        this.updateFinalRoundForPlayers(team.player2.id, finalScore);
                    }
                });
            });

            this.mytournament.rounds[roundindex].idleplayers.forEach(idlePlayer => {
                let finalScoreIdle = { finalcourt: maxmatchIndex + 1, score: -1 };
                this.updateFinalRoundForPlayers(idlePlayer.id, finalScoreIdle);
            });


            this.mytournament.players = LogicService.sortFinalLeaderBoardTable(this.mytournament.players);
            this.mytournament.finalplayers = LogicService.sortFinalLeaderBoardTable(this.mytournament.finalplayers);
        },

        recalculateSort() {
          console.log("Receive emit to recalulate SORT...")
          this.updateScore();
        },

        updateScore() {
            console.log("updateScore()...")
            //reset numberOfplayedRounds
            this.mytournament.players.forEach(player => {
                player.numberOfplayedRounds = 0;
                player.lastPlayedRoundindex = -1;
            });

            for (let i = 0; i < this.mytournament.rounds.length; i++) {
                if (this.mytournament.rounds[i].type == 'groupplay') {
                    let roundindex = i;
                    let round = this.mytournament.rounds[i];
                    //let roundMaxScore = round.maxscore;
                    //console.log("This ROUNDS max score is "+roundMaxScore)
                    if (!round.iscomplete) {
                        console.log("leaving updateScore() since round !complete")
                        return
                    }
                    for (let j = 0; j < round.matches.length; j++) {
                        let match = round.matches[j];
                        for (let k = 0; k < match.teams.length; k++) {
                            let team = match.teams[k];
                            if (Number.isInteger(team.score)) {
                                //TODO idea - inject color of court instead
                                let playersColor = match.court.color
                                this.updateScoreForPlayer(team.player1, team.score, roundindex, true, playersColor);
                                this.updateScoreForPlayer(team.player2, team.score, roundindex, true, playersColor);
                            } else {
                                console.log("Oppps - Score is undefeined")
                            }
                        }
                    }


                    if (typeof this.mytournament.rounds[roundindex].idleplayers !== "undefined") {
                        this.mytournament.rounds[roundindex].idleplayers.forEach(
                            (idlePlayer) => {
                                let roundIdleScore = this.mytournament.rounds[roundindex].idlescore
                                this.updateScoreForPlayer(idlePlayer, roundIdleScore, roundindex, false, -1);
                            }
                        );
                    }
                    //checkForLateArrivals
                    this.mytournament.players.forEach(player => {
                        if (typeof player.latearrival !== 'undefined' && player.latearrival > roundindex) {
                            //console.log("Calculating LATE arrivcal POINT LLLLAAAATTTEEEEE")
                            let score = Math.floor(this.latearrivalgetdefaultpoints * this.mytournament.points / 2);
                            this.updateScoreForPlayer(player, score, roundindex, false, -2)
                        }
                        if (typeof player.earlyleaver !== 'undefined' && player.earlyleaver <= roundindex) {
                            //console.log("Calculating EALY LEaver POINT LLLLAAAATTTEEEEE")
                            let score2 = Math.floor(this.latearrivalgetdefaultpoints * this.mytournament.points / 2);
                            this.updateScoreForPlayer(player, score2, roundindex, false, -3)
                        }
                    })
                    // todo - sort as number of winning match as second, thirs internal meets
                    //console.log("Will call for LogicService.sortGroupGamesLeaderBoardTable")
                    let isForFinalBoard = false;
                    this.mytournament.players = LogicService.sortGroupGamesLeaderBoardTable(this.mytournament.players, isForFinalBoard, this.mytournament.sortbymethod);

                    for (let i = 0; i < this.mytournament.players.length; i++) {
                        this.mytournament.players[i].position[roundindex] = i;
                    }
                }
            }
            console.log("updateScore() finished")
        },

        updateScoreForTeamPlayers(team, roundindex) {
            this.updateScoreForPlayer(team.player1, team.score, roundindex, true, -4);
            this.updateScoreForPlayer(team.player2, team.score, roundindex, true, -4);
        },
        updateScoreForPlayer(a_player, score, roundindex, wasActivePlayer, teamcolor_arg) {
            let roundMaxScore = this.mytournament.rounds[roundindex].maxscore;

            //console.log("updateScoreForPlayer. player=" +a_player.name + "  score = "+score +"  roundindex="+roundindex + "  roundMaxScore="+roundMaxScore)
            if (a_player == null) {
                return
            }
            this.mytournament.players.forEach((player) => {
                if (typeof player.scores == "undefined") {
                    player.scores = [];
                }
                if (typeof player.wins == "undefined") {
                    player.wins = [];
                }
                if (typeof player.wasIdle == "undefined") {
                    player.wasIdle = [];
                }
                if (typeof player.maxscores == "undefined") {
                    player.maxscores = [];
                }
                if (typeof player.teamcolor == "undefined") {
                    player.teamcolor = [];
                }



                if (a_player.id === player.id) {
                    player.scores[roundindex] = score;
                    if (wasActivePlayer && score > roundMaxScore / 2) {
                        player.wins[roundindex] = 1;
                    } else {
                        player.wins[roundindex] = 0;
                    }
                    let sum = 0;
                    let winsSum = 0;
                    for (let i = 0; i <= roundindex; i++) {
                        if (typeof player.scores[i] !== 'undefined') {
                            sum += player.scores[i];
                        }
                        if (typeof player.wins[i] !== 'undefined') {
                            winsSum += player.wins[i];
                        }
                    }
                    player.totalscore = sum;
                    player.totalwins = winsSum;
                    if (wasActivePlayer) {
                        player.lastPlayedRoundindex = roundindex;
                        player.numberOfplayedRounds += 1;
                        player.wasIdle[roundindex] = false
                    } else {
                        player.wasIdle[roundindex] = true
                    }
                    player.maxscores[roundindex] = roundMaxScore
                    player.teamcolor[roundindex] = teamcolor_arg
                }
            });
        },
        async newScore(newScoreObject) {
            console.log("App.vue - newScore" + newScoreObject.playerId + "  " + newScoreObject.roundIndex + "  " + newScoreObject.newScore);
            let keyIds = this.findKeyIdsFromRound(newScoreObject.playerId, newScoreObject.roundIndex)
            if (keyIds) {
                if (newScoreObject.newScore <= keyIds.maxscore) {
                    this.updatematchresult(keyIds.matchid, keyIds.teamid, parseInt(newScoreObject.newScore), keyIds.roundindex, false, keyIds.maxscore);
                    this.updateScore();
                    let isUpdate = true
                    await RestService.completeRound(this.mytournament._id, this.mytournament.rounds[newScoreObject.roundIndex], newScoreObject.roundIndex, isUpdate);
                }
            }
        },
        findKeyIdsFromRound(playerId, roundindex) {
            let round = this.mytournament.rounds[roundindex]
            let matches = round.matches
            for (let i = 0; i < matches.length; i++) {
                let teams = matches[i].teams
                for (let j = 0; j < teams.length; j++) {
                    let team = teams[j]
                    let player1 = team.player1
                    let player2 = { id: -1 }
                    if ((typeof(team.player2) !== 'undefined') && (team.player2 !== null)) {
                        player2 = team.player2
                    }

                    if (player1.id == playerId || player2.id == playerId) {
                        console.log("FOUND match")
                        let keyIds = { matchid: matches[i].id, teamid: team.id, roundindex: roundindex, maxscore: round.maxscore }
                        console.log("keyIds" + keyIds)
                        return keyIds
                    }
                }
            }
            console.log("Not found match. playerId=" + playerId)
            return null
        },

        CheckIfRoundMatchesIsReported(a_roundIndex) {
            let allReported = true;
            if ((typeof this.mytournament.rounds[a_roundIndex] == 'undefined') || (typeof this.mytournament.rounds[a_roundIndex].matches == 'undefined')) {
                return true;
            }
            this.mytournament.rounds[a_roundIndex].matches.forEach((match) => {
                match.teams.forEach((team) => {
                    if (!team.isreported) {
                        allReported = false;
                    }
                });
            });
            return allReported;
        },

        caclulateTotalSum(arrayOfNumber) {
            let sum = 0;
            if (typeof arrayOfNumber !== "undefined") {
                let mylength = arrayOfNumber.length;
                console.log("caclulateTotalSum. length" + mylength);
                arrayOfNumber.forEach((number) => {
                    sum += number;
                });
            }
            return sum;
        },






        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        // makeuniqueId() {
        //   var text = "";
        //   var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        //   for (var i = 0; i < 16; i++)
        //     text += possible.charAt(Math.floor(Math.random() * possible.length));
        //   return text;
        // },

        putTournament(tournamentid, rounds, roundindex) {
            RestService.putTournament(tournamentid, rounds, roundindex)
                .then(response => {
                    console.log("putTournament() MongoDB responce is:");
                    let debugM = JSON.stringify(response.data).length > 50 ? JSON.stringify(response.data).substring(1, 50) : JSON.stringify(response.data)
                    console.log(debugM);
                })
                .catch(e => {
                    console.log(e);
                })
        },
        getFinalPlayersFromFinalRound() {
            let tempfinalplayers = [];
            let finalRound = this.mytournament.rounds[this.currentroundindex];
            finalRound.teams.forEach(team => {
                tempfinalplayers.push(team.player1)
                tempfinalplayers.push(team.player2)
            })
            this.mytournament.finalplayers = tempfinalplayers;
        },

        async saveTour() {
            let tourRound = LogicService.generateTourRoundFromRound(this.mytournament);
            let tourRoundPlayersHash = this.getTourPlayersHash(tourRound.tourplayers)
            this.mytournament.tourround = tourRound;
            console.log("saveTour " + tourRound.name)
            //console.log("this.selectedtour =" +JSON.stringify(this.selectedtour))
            this.selectedtour.tourrounds.push(tourRound);
            localStorage.tournament = JSON.stringify(this.mytournament);
            try {
                let mytoken = this.$cookies.get('lop-jwt');
                let responseTour = await RestService.putTourRound(tourRound, tourRoundPlayersHash, this.mytournament.tourid, mytoken)
                console.log("responseTour = " + responseTour)
                this.savedToAmericaTour = true;
            } catch (error) {
                console.log("Can not store TOUR round")
                this.savedToAmericaTour = false;
            }
        },
        getTourPlayersHash(players) {
            let toursPlayersHash = {};
            for (let i = 0; i < players.length; i++) {
                if (typeof toursPlayersHash[players[i].id] === 'undefined') {
                    toursPlayersHash[players[i].id] = players[i].name
                }
            }
            return toursPlayersHash;
        },


        getFormattedDate(roundindex) {
            let aDate = this.mytournament.rounds[roundindex].time
            let myDate = new Date(aDate)
            let t = "" + myDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            return t;
        },



        filterFinalplayers() {
            let isForFinalBoard = true;
            return LogicService.sortGroupGamesLeaderBoardTable(this.mytournament.players, isForFinalBoard, this.mytournament.sortbymethod)

        },


        async handleMessage(msg) {
            console.log("handleMessage()...");
            this.iswaitingforDB = true;
            try {
                let myMessageObj = JSON.parse(msg);
                if (myMessageObj.tournamentid == this.mytournament._id) {
                    if (myMessageObj.type == "updatematchresult") {
                        console.log(" myMessageObj: updatematchresult()")
                        this.updatematchresult(myMessageObj.matchid, myMessageObj.teamid, myMessageObj.points, myMessageObj.roundindex, false);
                    }
                    if (myMessageObj.type == "rounds") {
                        console.log(" myMessageObj.type= rounds")
                        let response = await RestService.getRoundForTournament(this.mytournament._id, myMessageObj.roundindex)
                        console.log("...RestService.getRoundForTournament. HandleResponse. Got rounds from Mongo");
                        this.mytournament.rounds = response.data.rounds;
                        console.log("Has updated this.mytournament.rounds with MongoData");
                        this.currentroundindex = this.mytournament.rounds.length - 1;
                        console.log("this.mytournament.rounds[this.currentroundindex].type= " + this.mytournament.rounds[this.currentroundindex].type)
                        if (this.mytournament.rounds[this.currentroundindex].type == 'finalplay') {
                            this.getFinalPlayersFromFinalRound()
                            console.log("myMessageObj.type == rounds. AND FINALPLAY. populate finalplayers")
                        }
                        if (this.mytournament.rounds[this.currentroundindex].iscomplete) {
                            console.log("handleMessage: UpdateScor since round is complete")
                            this.updateScore();
                            this.scrolltobottom();
                        } else {
                            console.log("handleMessage: NOT UpdateScor since roud is incomplete")
                            this.scrolltotop();
                        }
                    }
                    if (myMessageObj.type == "finalround") {
                        console.log(" myMessageObj.type: finalround")
                        let response = await RestService.getRoundForTournament(this.mytournament._id, myMessageObj.roundindex)
                        console.log("...RestService.getRoundForTournament. HandleResponse. Got rounds");
                        this.mytournament.rounds = response.data.rounds;
                        this.currentroundindex = this.mytournament.rounds.length - 1;
                        if (this.mytournament.rounds[this.currentroundindex].iscomplete) {
                            console.log("handleMessage: updateScoreFinalPlayers since round is complete")
                            this.updateScoreFinalPlayers(this.currentroundindex);
                            this.scrolltobottom();
                        } else {
                            console.log("handleMessage: NOT updateScoreFinalPlayers since roud is incomplete")
                            this.scrolltotop();
                        }
                    }
                    if (myMessageObj.type == "players") {
                        console.log(" myMessageObj.type: players")
                        let myfields = { "players": 1 };
                        let responsePlayers = await RestService.getGenericFromTournament(this.mytournament._id, myfields)
                        console.log("...RestService.getGenericFromTournament. HandleResponse. Set players to= " + responsePlayers.data.players);
                        this.mytournament.players = responsePlayers.data.players;
                    }

                    localStorage.tournament = JSON.stringify(this.mytournament);
                }
            } catch (e) {
                alert(e);
            } finally {
                this.iswaitingforDB = false;
            }

        },
        sendMess(messageObject) {
            Socket.send(messageObject);
        },
        checkIfLocalStorageAppIsActive() {
            try {
                let localS = localStorage.tournament;
                if (typeof localS !== 'undefined') {
                    let tempTournament = JSON.parse(localStorage.tournament);
                    if (typeof tempTournament._id == 'undefined') {
                        console.log("Fresh tournamet exist, but will fail to load since ID = undefined")
                        return;
                    }
                    //let diffms = new Date() - new Date(tempTournament.createat)
                    let diffms = new Date() - new Date(tempTournament.lastdraw)
                    let diffMin = Math.round(diffms / 60000);
                    console.log("diffMin=" + diffMin)
                    let invalidMinutes = parseInt(this.invalidTournamentTime)
                    if (diffMin < invalidMinutes) {
                        console.log("pick up tournamnet from localstorage:" + tempTournament._id)
                        this.mytournament = tempTournament;
                        this.currentroundindex = this.mytournament.rounds.length - 1
                        let tempUser = JSON.parse(localStorage.user);
                        console.log("User.type  tempUser= " + tempUser)
                        if (typeof tempUser !== 'undefined' && tempUser.type !== 'undefined') {
                            this.user.type = tempUser.type
                        }
                    }
                }
            } catch (error) {
                console.error(error);
                return;
            }
        },
        async getResetPasswordUser(tempcode) {
            console.log("getResetPasswordUser " + tempcode);
            try {
                let response = await RestService.getemailfromcode(tempcode)
                console.log("getResetPasswordUser  = " + response.data);
                this.renewPasswordExistingEmail = response.data
                this.renewPasswordtemporaryCode = tempcode
            } catch (error) {
                console.log(error)
            }
        },
        populateDevData() {
            this.mytournament.name = "My Tournament"
            this.mytournament.type = "americano"
            this.mytournament.points = 21
            this.mytournament.maxscore = 21
            this.mytournament.sortbymethod = "points"
            this.mytournament.finaltype = "one_three"
            this.mytournament.finaldynamicscore = true
            this.mytournament.courts = [{ id: "101", name: "court1", color: "#c2bb89" }, { id: "102", name: "court2", color: "#89c2c2" }]
            //this.mytournament.players=[{name:"allan",id:"101", position:[] },{name:"berit",id:"102", position:[]}, {name:"cecar",id:"103", position:[]}, {name:"Devid",id:"104", position:[]}, {name:"erik",id:"105", position:[]}, {name:"Fred",id:"106", position:[]}, {name:"Gurr",id:"107", position:[]}, {name:"Haral",id:"108", position:[]}, {name:"Inger",id:"109", position:[]}]
            this.mytournament.players = [{ name: "Allan", id: "1101", position: [] }, { name: "Berit", id: "1102", position: [] }, { name: "Cecar", id: "1103", position: [] }, { name: "David", id: "1104", position: [] }, { name: "Erik", id: "1105", position: [] }, { name: "Inger", id: "1109", position: [] }]
            this.mytournament.allowplayertoreport = "true"
        },
        switchtype(mode) {
            //registerUserMode:"login, register, renewpasswordrequest, setnewpassword  "      
            console.log("switchtype. requesting this mode: " + mode)
            if (typeof mode !== 'undefined' && mode.length > 0) {
                this.registerUserMode = mode;
            } else {
                if (this.registerUserMode == 'login') {
                    this.registerUserMode = 'register'
                } else if (this.registerUserMode == 'register') {
                    this.registerUserMode = 'login'
                }
            }
        },
        openRankModal() {
            console.log("Will open the RANK modal")
            this.$bvModal.show("modal-rank");
        },
        openGamesHistoryModal() {
            console.log("Will open the RANK modal")
            this.$bvModal.show("modal-gameshistory");
        },

        openNamesModal() {
            console.log("Will open the Names modal")
            this.$bvModal.show("modal-names");
        },
        openextras() {
            console.log("Will open extras")
            this.$bvModal.show("modal-extras");
        },
        openloginmodal() {
            console.log("Will open the log in modal")
            this.$bvModal.show("modal-register-user");
        },
        openSelectTourModal() {
            console.log("Will open the log in modal")
            this.$bvModal.show("modal-register-tour");
        },
        isLoggedInQ() {
            //quick test
            console.log("isLoggedInQ");
            let myCookie = VueCookies.get("lop-jwt");
            console.log("myCookie =" + myCookie)
            if (myCookie !== null) {
                return true;
            }
            return false;
        },
        openlogoutmodal() {
            console.log("VueCookies.remove")
            this.isloggedin = false;
            VueCookies.remove("lop-jwt");
            this.setLopuser("")
        },
        userloggedinEmit(isTrue) {
            console.log("userloggedinEmit isTrue" + isTrue)
            this.isloggedin = true;
            this.populateClubPlayers()
        },
        async populateClubPlayers() {
            console.log("populateClubPlayers")
            let mytoken = VueCookies.get("lop-jwt");
            try {
                let responseClubPlayers = await RestService.getPlayers(mytoken)
                this.playersoptionlist = responseClubPlayers.data.players;
            } catch (error) {
                console.log(error);
            }
        },

        async isLoggedInDB() {
            console.log("isLoggedIn");
            let mytoken = VueCookies.get("lop-jwt");
            console.log("mytoken =" + mytoken)
            if (mytoken !== null) {
                try {
                    let response = await RestService.getLopuser(mytoken)
                    console.log("isLoggedInDB lopuser = " + response.data);
                    this.lopuser = response.data;
                    this.isloggedin = true;
                    return true;
                } catch (error) {
                    console.log(error)
                }
            }
            return false;
        },

        setLopuser(email) {
            console.log("setLopuser with email" + email)
            this.lopuser = email;
        },
        setselectedtour(tour) {
            if (typeof tour !== 'undefined' && tour._id.length > 0 && tour.tourname.length > 0) {
                console.log("setselectedtour with id" + tour._id)
                console.log("setselectedtour with name" + tour.tourname)
                this.selectedtour = tour;
                this.mytournament.name = tour.tourname
                this.mytournament.istour = true;
                if (typeof this.selectedtour.tourplayershash !== 'undefined') {
                    this.playersoptionlist = Object.values(this.selectedtour.tourplayershash)
                } else {
                    this.playersoptionlist = [];
                }
            }
            return;
        },
        populateClubPlayersIfLoggedInAndEmpty() {
            console.log("populateClubPlayersIfLoggedInAndEmpty")
            if (this.isloggedin) {
                console.log("yes, logged in")
                if (this.playersoptionlist.length == 0) {
                    console.log("empty playersoptionlist")
                    this.populateClubPlayers()
                }
            }
        }

    },

    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        Socket.$on("message", this.handleMessage);
        this.pollData()
    },
    beforeDestroy() {
        clearInterval(this.polling)
        Socket.$off("message", this.handleMessage)
    },

    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {
        console.log("app.mounted()...");
        this.appurl = process.env.VUE_APP_HOSTAPP
        console.log("envappurl:" + this.appurl);
        if (process.env.VUE_APP_POPULATEDEVDATA == 'true') {
            console.log("DEV. POPULATEING dev data");
            this.invalidTournamentTime = 2;
            this.populateDevData();
        }
        if (process.env.VUE_APP_USEAMERICANOTOUR == 'true') {
            console.log("VUE_APP_USEAMERICANOTOUR is TRUE")
            this.feature.push('tour')
            this.VUE_APP_USEAMERICANOTOUR = true;
        }
        if (process.env.VUE_APP_USEAMERICANOTEAMS == 'true') {
            console.log("VUE_APP_USEAMERICANOTEAMS is TRUE")
            this.feature.push('teams')
            this.VUE_APP_USEAMERICANOTEAMS = true;
        }
        if (process.env.VUE_APP_USEPLAYERREPORT == 'true') {
            console.log("VUE_APP_USEPLAYERREPORT is TRUE")
            this.feature.push('playerreport')
            this.VUE_APP_USEPLAYERREPORT = true;
        }

        if (process.env.VUE_APP_USEEXPERIMENTAL == 'true') {
            console.log("VUE_APP_USEEXPERIMENTAL is TRUE")
            this.VUE_APP_USEEXPERIMENTAL = true;
        }

        this.isLoggedInDB()

        let defaultTimeOut = 3000;
        this.timeoutId = setTimeout(() => {
            this.populateClubPlayersIfLoggedInAndEmpty()
        }, defaultTimeOut);

        const urlParams = new URLSearchParams(window.location.search);
        let featurepwdreset = urlParams.get("pwdreset");
        if (featurepwdreset != null) {
            console.log("feature pwdreset= " + featurepwdreset);
            this.getResetPasswordUser(featurepwdreset);
            console.log("Will open the log in modal")
            this.$bvModal.show("modal-register-user");
            //this.isRenewPassword=true;   
            this.registerUserMode = 'setnewpassword'
            return;
        }

        let featureflagTEAMS = urlParams.get("teams");
        console.log("check featureflag teams");
        if (featureflagTEAMS != null) {
            console.log("featureflagTEAMS= " + featureflagTEAMS);
            if (featureflagTEAMS === '1') {
                this.VUE_APP_USEAMERICANOTEAMS = true;
                this.feature.push('teams')
            }
        }

        let featureflagTOUR = urlParams.get("tour");
        console.log("check featureflag tour");
        if (featureflagTOUR != null) {
            console.log("featureflagTOUR= " + featureflagTOUR);
            if (featureflagTOUR === '1') {
                this.VUE_APP_USEAMERICANOTOUR = true;
                this.feature.push('tour')
            }
        }

        let currentid_temp = urlParams.get("tournamentid");
        if (currentid_temp == null || currentid_temp < 23) {
            console.log("Not loading any remote Object. No tournam in url");
            this.checkIfLocalStorageAppIsActive();
            return;
        }

        RestService.getTournament(currentid_temp)
            .then(response => {
                if (response.data == null) {
                    this.showCanNotLoadTournamnet = true;
                    return;
                }
                console.log("Set mytourmante to response data");

                this.mytournament = response.data;
                this.currentroundindex = this.mytournament.rounds.length - 1;
                this.currentid = this.mytournament._id;
                console.log("currentid=" + this.currentid);
                this.updateScore();
                console.log("Will set user to slave")
                this.user.type = "slave";
                localStorage.tournamentFromMongo = JSON.stringify(this.mytournament);
                //set up websocket listener
                this.sendMess(JSON.stringify({ "register": this.mytournament._id, "usertype": "slave" }));
            })
            .catch(error => {
                console.log(error)
                this.axioserrored = true
                this.showCanNotLoadTournamnet = true;
            })
            .finally(() => this.axiosloading = false)
    },

    watch: {
        isloggedin: function(newVal, oldVal) {
            console.log('isloggedin changed: ', newVal, ' | was: ', oldVal)
            if (!newVal && oldVal) {
                console.log('USER LOGGEd OUT')
                this.mytournament.istour = false;
            }
        }


    },

    computed: {
        getnavbarusername: function() {
            if (this.lopuser.length > 6) {
                return this.lopuser.substring(0, 8) + "..."
            } else {
                return "Login"
            }
        },
        disablePressCompleteRoundAndDraw: function() {
            if (!this.isAllMatchesInCurrentRoundReported || this.mytournament.rounds[this.currentroundindex].iscomplete || this.iswaitingforDB) {
                return true;
            }
            return false;
        },
        activePlayersList: function() {
            let activePlayersList = []
            if (this.mytournament.players.length > 0) {
                for (let i = 0; i < this.mytournament.players.length; i++) {
                    activePlayersList.push(this.mytournament.players[i].name)
                }
            }
            return activePlayersList;
        },

        disableSaveToTour: function() {
            if (Object.keys(this.selectedtour).length === 0) {
                return true;
            }
            return !this.mytournament.rounds[this.currentroundindex].iscomplete || this.savedToAmericaTour
        },
        tournamenthasinit: function() {
            return typeof this.mytournament.rounds[this.currentroundindex] !== 'undefined'
        },
        isfinalplay: function() {
            return (typeof this.mytournament.rounds[this.currentroundindex] !== 'undefined' && this.mytournament.rounds[this.currentroundindex].type == 'finalplay')
        },
        isgroupplay: function() {
            return (typeof this.mytournament.rounds[this.currentroundindex] !== 'undefined' && this.mytournament.rounds[this.currentroundindex].type == 'groupplay')
        },
        isAllMatchesInCurrentRoundReported: function() {
            return this.CheckIfRoundMatchesIsReported(this.currentroundindex);
        },
        showfriendscanreport: function() {
            console.log("showfriendscanreport=" + this.mytournament.allowplayertoreport)
            return this.mytournament.allowplayertoreport == "true";
        },
        showTournamentAsSideBar: function() {
            console.log("showTournamentAsSideBar=" + this.mytournament.isactive)
            return this.mytournament.isactive;
        },
        numberOfVisibleColumns: function() {
            // console.log("this.window.width = "+this.window.width)
            if (this.window.width > 1400) {
                return 14;
            } else if (this.window.width > 1000) {
                return 10;
            } else if (this.window.width > 800) {
                return 7;
            } else if (this.window.width > 600) {
                return 5;
            } else if (this.window.width > 400) {
                return 4;
            } else if (this.window.width > 300) {
                return 3;
            } else {
                return 3;
            }
        },
        currentrounddisplaysize() {
            if (this.window.width > 500) {
                return {
                    'font-size': '4rem',
                }
            } else {
                return {
                    'font-size': '2.5rem',
                }
            }
        }
    },

};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

body {
    background: linear-gradient(65deg, #edf0f0, #e3f8f8);
    background-color: #edf0f0;
    padding-bottom: 4rem;
}

.buttoncontainer {
    display: flex;
    justify-content: space-between;
}

.leftbutton {
    margin-right: 1rem;
}

.rightbutton {
    margin-left: 1rem;
}

.headercontainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
}

.currentroundcontainer {
    /* background-color: lightgreen; */
    /* display: inline-block; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    border: 2px solid black;
    width: 7rem;
}

.currentroundlabel {
    background-color: lightgray;
    text-align: center;
    border-bottom: 1px solid black;
    font-size: 120%;
}

.currenttime {
    background-color: lightgray;
    border-top: 1px solid black;
    text-align: center;
}

.currentroundnumber {
    background-color: whitesmoke;
    /* border: 1px solid black; */
    text-align: center;
    /* display: inline-block; */
    /* font-size: 4rem; */
}

.headerone h1 {
    font-family: "Permanent Marker", sans-serif;
    font-style: italic;
    text-align: center;
    margin-bottom: 0rem;
    color: rgba(110, 100, 6, 0.959);
}

.headerone h6 {
    text-align: center;
    margin: 0rem;
}

.headerone h4 {
    text-align: center;
    margin: 0rem;
}

.headerone {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.headertwo {
    flex-grow: 0;
    width: 200px;
    margin: 0 0rem 0 2rem;
}

.headerthree {}

.editbutton {
    text-align: right;
    margin: 1rem;
}

.insidethree {
    transform: rotate(0deg) translateX(0.2rem) translateY(2.0rem);
    writing-mode: vertical-rl;
}

.americanobutton {
    margin: 1rem 0 0.5rem 0;
}

.formeditbutton {
    margin: 0 0.5rem 0 0;
}

.rootamericano {
    margin: 0.5rem;
}

.navbarflex {
    justify-content: space-between !important;
    ;
}
</style>
