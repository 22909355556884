<template>
  <div class="register-tournament" id="register-tournament">
    <div>            
      <b-form @submit="onSubmit2" @reset="onReset">
        <div class="register-tournamentdetails">
          <b-form-group>
            <label for="this.viewtype +'-input-1'" class="inputlabel">Tournament name</label>
            <!-- id="input-1" -->
            <b-form-input              
              :id="this.viewtype +'-input-1'"
              v-model="tournament.name"              
              placeholder="My Tournament"
              :state="state"               
            ></b-form-input>
             <b-form-invalid-feedback :state="state">
              Name of the Tournament.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label for="type-radios" class="inputlabel">Tournament type</label>
            <b-form-radio
              v-model="tournament.type"
              name="type-radios"
              value="americano"
              :disabled="this.tournament.isactive && this.tournament.type === 'americanoteam'"
              >Americano</b-form-radio
            >
            <b-form-radio                  
              v-if="feature.includes('teams')"
              v-model="tournament.type"
              name="type-radios"
              value="americanoteam"
              :disabled="this.tournament.isactive && (this.tournament.type === 'americano' || this.tournament.type === 'mexicano')"
              >Americano Team</b-form-radio
            >

            <b-form-radio              
              v-model="tournament.type"
              name="type-radios"
              value="mexicano"
              :disabled="this.tournament.isactive && this.tournament.type === 'americanoteam'"
              >Mexicano</b-form-radio
            >
            <!-- <b-form-radio                       
              v-if="feature.includes('tour')"
              v-model="tournament.type"
              name="type-radios"
              value="tour"
              :disabled="!validForTour"
              :style="{ color: activeColor}"
              >TOUR-Americano <span v-if="!validForTour" style="color:blue" @click="emitopenloginmodal" >-Log In</span></b-form-radio
            >  -->
             <b-form-checkbox
                v-if="feature.includes('tour')"
                id="checkbox-1"
                v-model="tournament.istour"
                name="checkbox-1"
                :disabled="!validForTour"
              >

                <span :style="[validForTour ? {} : {'color':'grey'}]"> Play Tour </span>  
                <span v-if="!validForTour" style="color:blue" @click="emitopenloginmodal" >-Log In</span>
            </b-form-checkbox>                            
              

          </b-form-group> 
          
          <div v-if="tournament.istour">
            <b-button v-on:click="$emit('openSelectTourModal')"  :variant="getVariandForSeceltbutton()" >
              {{buttonLabelSelectTour}}
            </b-button>
          </div>

          <b-form-group>
            <label for="type-radios" class="inputlabel">Playing points</label>
            <b-form-radio
              v-model="tournament.points"
              name="points-radios"
              value=11
              type="number"
              >11</b-form-radio
            >
            <b-form-radio
              v-model="tournament.points"
              name="points-radios"
              value=21
              type="number"
              >21</b-form-radio
            >
            <b-form-radio
              v-model="tournament.points"
              name="points-radios"
              value=24
              type="number"
              >24</b-form-radio
            >
            <b-form-radio
              v-model="tournament.points"
              name="points-radios"
              value=32
              type="number">
              32
            </b-form-radio>
            <!--b-form-radio
              v-model="tournament.points"
              name="points-radios"
              value=custom
              type="number">
              custom 
            </b-form-radio-->
          </b-form-group>       


          <b-form-group>
              <b-form-radio v-model="tournament.sortbymethod"  name="some-radios" value="points">Rank on Points</b-form-radio>
              <b-form-radio v-model="tournament.sortbymethod"  name="some-radios" value="wins">Rank on Wins</b-form-radio>
          </b-form-group>       

          <b-form-group v-if="tournament.type!='americanoteam'">
            <label for="finaltype-radios" class="inputlabel">Final partners</label>
            <b-form-radio
              v-model="tournament.finaltype"
              name="finaltype-radios"
              value="one_three"
              >1&3 against 2&4</b-form-radio
            >
            <b-form-radio
              v-model="tournament.finaltype"
              name="finaltype-radios"
              value="one_four"
              >1&4 against 2&3</b-form-radio
            >
            <b-form-radio
              v-model="tournament.finaltype"
              name="finaltype-radios"
              value="one_two"
              >1&2 against 3&4</b-form-radio
            >
          </b-form-group>


          <div v-if="tournament.type!='americanoteam'">
          <b-icon-info-circle variant="primary" v-b-tooltip.hover.righttop="'In the Final, you can either play a set, of whatever number of games, or points. You do NOT need to decide now!'">                             
          </b-icon-info-circle> 
          <span style="font-style:italic;color:gray" > In the final, you can play whatever (Set, Points... ) </span>
          </div>


          <b-form-group v-if="feature.includes('playerreport')">
            <label for="letplayersreport-radios" class="inputlabel">Allow players to report</label>
            <b-form-radio
              v-model="tournament.allowplayertoreport"              
              name="letplayersreport-radios"              
              value=true              
              >Yes - we're friends. Everyone can report.</b-form-radio
            >
            <b-form-radio
              v-model="tournament.allowplayertoreport"              
              name="letplayersreport-radios"              
              value=false
              >No - Only me on this device can report.</b-form-radio
            >           
          </b-form-group>          

        </div>
        <div class="register-court">
          <h4 class="inputlabel extrapaddingforlabel">Register Court</h4>
          <register-item @add:item="addCourt" buttonlabel="court" label="court" :courtslength="tournament.courts.length"> </register-item>
          <list-items
            v-bind:items="tournament.courts"
            label="Court"  
            :screenwidth="screenwidth"   
            :iseditanexistingtournament="tournament.isactive"       
            @delete:item="deleteCourt"
            @edit:court="editCourt"
          >
          </list-items>
        </div>
        
        <div class="register-player">
          <h4 v-if="tournament.type==='americanoteam'" class="inputlabel extrapaddingforlabel">Register Teams</h4>
          <h4 v-else class="inputlabel extrapaddingforlabel">Register Players</h4>
          <register-item 
            @add:item="addPlayer" 
            @addSetOfPlayers="addSetOfPlayers"
            :buttonlabel="islateplayerPrefix" 
            label="player" 
            :playerslength="tournament.players.length" 
            :type="tournament.type"
            :istour="tournament.istour"
            :playersoptionlist="playersoptionlist"
            :showCanNotAddItem="identicalItemName"
          >             
          </register-item>

          <list-items
            v-bind:items="tournament.players"            
            label="Player"
            :type="tournament.type"
            :screenwidth="screenwidth"          
            :iseditanexistingtournament="tournament.isactive"            
            @delete:item="deletePlayer"
            @undelete:item="unDeletePlayer"
            @edit:court="editPlayer"
            @forceIdle:itemId="forceIdle"          
            @releaseIdle:itemId="releaseIdle"          
            
          >
          </list-items>
        </div>        

        <div class="buttoncontainer">
          <span v-if="this.tournament.isactive">
            <b-button v-b-toggle.sidebar-1 variant="primary" class="americanobutton leftbutton">Back to Tournament</b-button>
          </span>
          <span v-else>
            <b-button block :disabled="registerTournamentDisabled" type="submit" variant="primary" class="americanobutton leftbutton">
              Create Tournament
            </b-button>          
          </span>

          <b-button v-if="this.tournament.isactive" v-on:click="canceltournament"  variant="outline-danger" class="americanobutton rightbutton">
            Cancel Tournament
          </b-button>           
        </div>        
      </b-form>

    </div>    
  </div>
</template>

<script>
import RegisterItem from "./RegisterItem.vue";
import ListItems from "./ListItems.vue";
import LogicService from './../services/LogicService.js'
import VueCookies from 'vue-cookies'


export default {
  name: "register-tournament",
  components: {
    RegisterItem,
    ListItems,    
  },
  data() {
    return {
      createtournamenterrormessage:"",
      identicalItemName:false,            
    };
  },
  props: {
    tournament: Object,
    colors:Array,
    screenwidth:Number,    
    viewtype:String,
    validForTour:Boolean,
    feature:Array,
    selectedTour:String,
    playersoptionlist:Array,
    tourplayersHash : Object,
  },
  methods: {
    onSubmit2(event) {
      console.log("COMPONENT REGISTERTOURNAMENT onSubmit");
      event.preventDefault();
      if (this.tournament.name.length <3) {          
          return;
      }
      this.createTournament();      
    },
    onReset(event) {
      event.preventDefault();      
      this.form.email = "";
    },
    getVariandForSeceltbutton() {
      console.log("getVariandForSeceltbutton");
      if (typeof this.selectedTour !== 'undefined' && this.selectedTour.length > 0) {
        return "outline-primary"  
      } else {
        return "danger"
      }      
    },
    handleCookie() {
      // try get cookie
      console.log("Handling cookie")
      let myCookie = this.$cookies.get('lop-startTournament');        
      if (myCookie == null || typeof myCookie === 'undefined' ) {
        myCookie = 0
      }
      myCookie= parseInt(myCookie) + 1
      VueCookies.set("lop-startTournament",myCookie,"2m");         
    },

    createTournament() {
      if (this.tournament.courts.length <1 || this.tournament.players.length<4) {
        console.log("NOT createing tournament. too few players and court")
        return;
      }     
      this.tournament.idlepoints = Math.ceil(this.tournament.points / 2);      
      this.tournament.isactive = true;      
      this.$emit("add:tournament", this.tournament);
    },
    addCourt(courtName) {
      this.handleCookie()
      console.log("addCourt");
      let myId = LogicService.getUniqueIdWithDate()
      let court = { name: courtName, id: myId };   
      if (this.tournament.isactive) {
        console.log("ADDING court on active tournament from SIDEBAR")
        //court.color = this.colors[this.tournament.courts.length]  
        court.color = this.findUnusedColor();
      }       
      console.log(JSON.stringify(court))
      this.tournament.courts.push(court);      
      //this.tournament.courts = this.tournament.courts.sort(function (a, b) {                    
        //  return a.name.localeCompare(b.name);
      //});     
    },
    findUnusedColor() {
        for (let i=0; i<this.colors.length; i++) {
          let colorUsed = false;
          for (let j=0; j<this.tournament.courts.length; j++) {
            if (this.colors[i] === this.tournament.courts[j].color) {
              colorUsed = true;
            }
          }          
          if (!colorUsed) {
            return this.colors[i]
          }
        }
        return "#E1E1E1";
    },
    editCourt(item) {
      console.log("editCourt item = " + item);
    },
    deleteCourt(id) {
      console.log("deltetCourt coirtitem ID = " + id);
      for (let i = 0; i < this.tournament.courts.length; i++) {
        if (this.tournament.courts[i].id == id) {
          this.tournament.courts.splice(i, 1);
          return;
        }
      }
    },
    findBottomScore() {
      let bottomscores = [];
      let minscore = 100000;
      this.tournament.players.forEach(player => {
          let scoreTemp = player.scores;
          let scoreSum = 0;
          for (let i=0; i<scoreTemp.length; i++) {
            if (typeof scoreTemp[i] !== 'undefined') {
              scoreSum += scoreTemp[i];
            }
          }
          if (scoreSum <minscore) {
            minscore = scoreSum;
            bottomscores = scoreTemp;
          }
      });
      return {bottomscores:bottomscores,minscore:minscore} ;
    },
    addSetOfPlayers() {
      if (typeof this.tourplayersHash !== 'undefined') {        
        //we already have all players in this.tourplayersHash
        let myNames = Object.values(this.tourplayersHash)
        console.log("addSetOfPlayers. length = "+myNames.length)
        for (let i=0; i<myNames.length; i++) {
          this.addPlayer(myNames[i]);
        }        
      }
    },
    addPlayer(playerName) {
      // check if name exist
      this.identicalItemName = false;
      for (let i=0; i<this.tournament.players.length; i++) {
        if (this.tournament.players[i].name.trim() == playerName.trim() ) {
          console.log("Same name. Can not add")
          this.identicalItemName = true;
          return;
        }
      }
      
      let scores = [];
      let totalscore = 0;
      let latearrival = 0;
      let forceidle = false;
      if (this.tournament.isactive) {
        console.log("add LATE Player: " + playerName);
        latearrival = this.tournament.rounds.length        
      }
      let myId = LogicService.getUniqueIdWithDate()
      if (typeof this.tourplayersHash !== 'undefined') {
        let myKeys = Object.keys(this.tourplayersHash);
        for (let i=0; i<myKeys.length ; i++) {
          if (this.tourplayersHash[myKeys[i]] == playerName ) {
            myId = myKeys[i];
          }
        }      
      }

      console.log("addPlayer: " + playerName);      
      let player = { name: playerName, id: myId, scores:scores, totalscore:totalscore, position:[], latearrival:latearrival, forceidle:forceidle };
      this.tournament.players.push(player);
      this.tournament.players = this.tournament.players.sort(function (a, b) {                    
          return a.name.localeCompare(b.name);
      });     
      if (latearrival > 0) {
        this.$emit("latearrival:player"  );
      }      
    },
    editPlayer(item) {
      console.log("editPlayer item = " + item);
    },
    forceIdle(id) {      
      for (let i = 0; i < this.tournament.players.length; i++) {
        if (this.tournament.players[i].id == id) {
          if (this.tournament.isactive) {                                    
            this.tournament.players[i].forceidle = true            
          }          
          return;
        }
      }
    },
    releaseIdle(id) {      
      for (let i = 0; i < this.tournament.players.length; i++) {
        if (this.tournament.players[i].id == id) {
          if (this.tournament.isactive) {                        
            this.tournament.players[i].forceidle = false            
          }          
          return;
        }
      }
    },

    deletePlayer(id) {
      console.log("deletePlayer  ID = " + id);
      for (let i = 0; i < this.tournament.players.length; i++) {
        if (this.tournament.players[i].id == id) {
          if (this.tournament.isactive) {
            this.tournament.players[i].earlyleaver = this.tournament.rounds.length  
            this.tournament.players.push("slask")
            this.tournament.players.pop();
            this.$emit("earlyLeaver:player" );
          } else {
            this.tournament.players.splice(i, 1);
          }          
          return;
        }
      }
    },

    unDeletePlayer(id) {
      console.log("unDeletePlayer  ID = " + id);
      for (let i = 0; i < this.tournament.players.length; i++) {
        if (this.tournament.players[i].id == id) {
          if (this.tournament.isactive) {            
            delete this.tournament.players[i].earlyleaver
            this.tournament.players.push("slask")
            this.tournament.players.pop();
            this.$emit("comingBack:player" );
          }          
          return;
        }
      }
    },

    canceltournament() {
      this.$emit("cancel:tournament");
    },
    emitopenloginmodal() {
      this.$emit('openloginmodal')
    }
    
  },
  computed: {
    state() {
      return this.tournament.name.length >= 3;      
    },
    invalidFeedback() {
      if (this.tournament.name.length > 0) {
        return "Enter at least 3 characters.";
      }
      return "Please enter something.";
    },
    registerTournamentDisabled() {            
      console.log("computed registerTournamentDisabled")
      if (this.tournament.isactive) {                
        return true;
      }
      if (this.tournament.courts.length==0 || this.tournament.players.length<4 || this.tournament.name.length < 3) {        
        return true;
      }
      if (this.tournament.istour) {
        console.log("IS TOur is TRUE");
        if (typeof this.selectedTour === 'undefined' || this.selectedTour.length < 2) {        
          return true;
        }        
      }                
      return false;      
    },
    islateplayerPrefix() {
      let labelString = ""
      if (this.tournament.isactive) {
        labelString = labelString + "late" 
      } 
      if (this.tournament.type === 'americanoteam') {
        labelString = labelString + " team" 
      } else {
        labelString = labelString + " player" 
      }
      return labelString;
    },    
    activeColor : function () {
      if (this.validForTour) {
        return 'black'
      } 
      return 'gray'      
    },
    buttonLabelSelectTour() {
      if (typeof this.selectedTour !== 'undefined' && this.selectedTour.length > 0) {
        console.log("buttonLabelSelectTour")
        return this.selectedTour
      } else {
        return "Select/Create Tour"
      }      
    },    

            

  },
  mounted () {
    
  },
  watch: { 
    'tournament.points': function (newVal, oldVal){
         console.log("changing MAXSCORE value OLD=" +oldVal + "  NEW="+ newVal)
         this.tournament.maxscore = parseInt(this.tournament.points)
         this.tournament.idlepoints = Math.ceil(this.tournament.points / 2);      
         //to work with changes in someOtherProp
    },     
    'tournament.sortbymethod': function (newVal, oldVal){
         console.log("changing sortbymethod value OLD=" +oldVal + "  NEW="+ newVal)
         if (newVal !== oldVal) {
            this.$emit("recalculateSort");
         }         
    },    
    'tournament.players.length': function (newVal, oldVal){
         console.log("changing PLAYERS length value OLD=" +oldVal + "  NEW="+ newVal)
         if (newVal !== oldVal) {
            this.$emit("recalculateSort");
         }         
    },    

  },
};
</script>

<style scoped>
label {
  font-size: 1.3rem;
  color: black;  
  margin-top: 0.6rem;
}
.inputlabel {  
  background-color: WhiteSmoke;
  border-color: darkgray;
  border-style: solid ;
  border-width: 1px 1px 0px 1px;
  border-radius: 0 25px 0  0;
  width: 60%;
  min-width: 17rem;
  max-width: 20rem;
  padding-left: 0.5rem;    
}

.extrapaddingforlabel {
  padding-top: 0.3rem ;
  padding-bottom: 0.3rem;
  margin-bottom: 0.0rem;
}

.register-court,
.register-player,
.register-tournamentdetails {
  border: 1px solid lightgray;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;  
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  
}
.register-tournament {
  max-width: 960px;
}

</style>