import { render, staticRenderFns } from "./Gameshistory.vue?vue&type=template&id=09c51d96&scoped=true"
import script from "./Gameshistory.vue?vue&type=script&lang=js"
export * from "./Gameshistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c51d96",
  null
  
)

export default component.exports