<template>


  <div id="list-items">

   <!-- <b-card class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ items }}</pre>
</b-card> -->
      
    <p v-if="items.length < 1" class="empty-table"></p>
    <table style="width:100%" v-else>
      <thead>
        <tr>
          <th style="min-width:2rem"># </th>
          <th style="min-width:4rem">{{ label }}</th>
          <th>Actions</th>
          <th v-if="label === 'Player'" >Idle
            <b-icon-info-circle v-if="true" variant="danger" v-b-tooltip.hover.left="'Force This player go IDLE next round (ONE round)'">                 
            </b-icon-info-circle>             
          </th> 
        </tr>
      </thead>
      <tbody>        
         <tr v-for="(item, index) in items" :key="item.id">                     
          <td> {{index+1}} </td>
          <td v-if="editing === item.id">
            <input type="text" v-model="item.name" class="editforfield"/>
          </td>

          <td v-else v-bind:class="{ 'text-muted' : item.earlyleaver}">{{ item.name }}</td>
          
          <td v-if="editing === item.id">
            <b-button :disabled="buttondisabled(item.name)" @click="editItem(item)" variant="outline-primary" class="formeditbutton">
              <b-icon-check-circle variant="success"></b-icon-check-circle>
              Save
            </b-button>
            <b-button
              class="muted-button formeditbutton"
              @click="cancelEdit(item)"
              variant="outline-primary"
            >
              <b-icon-x-circle variant="danger"></b-icon-x-circle>
              <span v-if="showtextinsidebuttons"> Cancel</span>               
            </b-button>
          </td>
          

          
          <td v-else>
            <b-button
              v-if="!item.earlyleaver>0"
              @click="$emit('delete:item', item.id)"
              variant="outline-primary"
              class="formeditbutton"
              :disabled="(item.earlyleaver>0)"    
            >
              <b-icon-trash variant="danger"></b-icon-trash>
              <span v-if="showtextinsidebuttons"> Delete</span> 
            </b-button>

            <b-button
              v-if="item.earlyleaver>0"
              @click="$emit('undelete:item', item.id)"
              variant="outline-primary"
              class="formeditbutton"
              :disabled="(!item.earlyleaver>0)"    
            >
              <b-icon-emoji-heart-eyes variant="success"></b-icon-emoji-heart-eyes>
              <span v-if="showtextinsidebuttons"> ReJoin </span> 
            </b-button>


            <b-button @click="editMode(item)" variant="outline-primary" class="formeditbutton" :disabled="item.earlyleaver>0">
              <b-icon-pencil variant="success"></b-icon-pencil>
              <span v-if="showtextinsidebuttons"> Edit</span> 
            </b-button>
          </td>     
          
          <td v-if="label === 'Player' && !item.earlyleaver>0">           
            <b-icon-pause-circle  v-if="item.forceidle == true"  variant="danger"   @click="releaseIdle(item.id)"  ></b-icon-pause-circle>
            <b-icon-play-circle  v-if="!item.forceidle == true && !isActiveMultipleOfFour()"  variant="success"   @click="forceIdle(item.id)"  ></b-icon-play-circle>            
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "list-items",
  data() {
    return {
      editing: null,
    };
  },
  methods: {
    // editMode(id) {
    //   this.editing = id;
    // },
    editMode(item) {
      this.cachedItem = Object.assign({}, item);
      this.editing = item.id;
    },
    cancelEdit(item) {
      Object.assign(item, this.cachedItem);
      this.editing = null;
    },

    editItem(item) {
      if (item.name.length<3 ) return;
      this.$emit("edit:item", item);
      this.editing = null;
    },
    forceIdle(itemId) {      
      for (let i=0; i<this.items.length; i++) {
        if (this.items[i].id == itemId) {
          this.items[i].forceidle = true;          
        }
      }
      this.$emit("forceIdle:itemId", itemId);
    },
    releaseIdle(itemId) {      
      for (let i=0; i<this.items.length; i++) {
        if (this.items[i].id == itemId) {
          this.items[i].forceidle = false;          
        }
      }      
      this.$emit("releaseIdle:itemId", itemId);
    },


  },
  props: {
    items: Array,
    label: String,
    screenwidth:Number,
    iseditanexistingtournament:Boolean,
    type:String,
  },
  computed: {
    // state() {
    //     return this.item.name.length >= 2
    //   },   
    showtextinsidebuttons() {
      if (this.iseditanexistingtournament) {
        if (this.screenwidth < 420) {
          return false;
        }
      }
      if (this.screenwidth < 370) {
        return false;
      }
      return true;
    },


    buttondisabled() {
      return (itemName) => {
        console.log("itemName =" +itemName)
        if (this.type === 'americanoteam') {
          let noSeparator = !itemName.includes(" & ")
          let tooLong = itemName.length > 25
          //return (!itemName.includes(" & "))          
          return (noSeparator || tooLong)
        }
        if (itemName.length<3 ) {
          return true;
        } 
        if (itemName.length>11 ) {
          return true;
        } 

        return false      
      }
    },
    isActiveMultipleOfFour() {
      return () => {        
        let playersCount = 0        
        for (let i=0; i<this.items.length; i++) {
          if (! (this.items[i].earlyleaver || this.items[i].forceidle)) {
            playersCount++
          } 
        }
        if (playersCount%4 == 0) {
          return true
        }
        return false 
      }
    },    

  },

watch: {
    // items: function (val) {
    //   console.log("WATCH of items. val = " +val);
    //   console.log(JSON.stringify(this.items));
    //   for (let i=0; i<this.items.length; i++) {
    //     this.items[i].pos = i+1
    //   }
    // },

  }

};
</script>

<style scoped>
.editforfield {
  max-width: 5rem;
}

td {
  text-align: left;
}
</style>