import uniqueId from "lodash.uniqueid";

// var mycounter;
var letterPlayerID_map = {};
var letterPlayerObjectMap = {};

export default {
    
    sortGroupGamesLeaderBoardTable(myplayers_arg, isForFinalBoard, sortbymethod) {
        console.log("sortGroupGamesLeaderBoardTable")
        let sortbypoints = true;
        if (sortbymethod == 'wins') {
            sortbypoints = false;
        }
        let myplayers = JSON.parse(JSON.stringify(myplayers_arg));        
        myplayers = myplayers.sort(function ( a, b ) {
            if (isForFinalBoard) {
                if (typeof a.earlyleaver === 'undefined' && typeof b.earlyleaver !== 'undefined') {                    
                    return -1
                } else if (typeof a.earlyleaver !== 'undefined' && typeof b.earlyleaver === 'undefined') {                    
                    return 1
                }

                if(typeof b.forceidle !== "undefined" && b.forceidle) {
                    return -1
                }
                if(typeof a.forceidle !== "undefined" && a.forceidle) {
                    return 1
                }

                if (typeof b.final !== 'undefined' &&  typeof a.final !== 'undefined')  {
                    if (b.final.finalcourt == a.final.finalcourt) {                        
                        return b.final.score - a.final.score;
                    } else {
                        return a.final.finalcourt - b.final.finalcourt;
                    }
                } else if (typeof a.final !== 'undefined') {
                    return -1
                } else if (typeof b.final !== 'undefined') {
                    return 1
                }
            }

            if (sortbypoints) {
                if (b.totalscore - a.totalscore == 0) {
                    //console.log("totalscore even. Counting winsSum in leaderboard. a.totalwins=" +a.totalwins + "   b.totalwins="+b.totalwins)
                    if (b.totalwins - a.totalwins == 0) {
                        let a_sum = 0;
                        for( let i = 0; i < a.position.length; i++ ){
                            a_sum += parseInt( a.position[i], 10 ); 
                        }
                        let b_sum = 0;
                        for( let j = 0; j < b.position.length; j++ ){
                            b_sum += parseInt( b.position[j], 10 ); 
                        }                    
                        return (a_sum - b_sum)
                    } else {
                        return b.totalwins - a.totalwins
                    }                
                } else {
                    return b.totalscore - a.totalscore
                }
            } else {
                if (b.totalwins - a.totalwins == 0) {                    
                    if (b.totalscore - a.totalscore == 0) {
                        let a_sum = 0;
                        for( let i = 0; i < a.position.length; i++ ){
                            a_sum += parseInt( a.position[i], 10 ); 
                        }
                        let b_sum = 0;
                        for( let j = 0; j < b.position.length; j++ ){
                            b_sum += parseInt( b.position[j], 10 ); 
                        }                    
                        return (a_sum - b_sum)
                    } else {
                        return b.totalscore - a.totalscore
                    }                
                } else {
                    return b.totalwins - a.totalwins
                }
            }
        });      
        return myplayers;
    },

    
    sortFinalLeaderBoardTable(myplayers_arg) {
        // FInal IS played
        let myplayers = JSON.parse(JSON.stringify(myplayers_arg));
        
        myplayers = myplayers.sort(function (a, b) {
            // @todo Take all players, not only finalplayers. check if finalcourt exist 

            if (typeof b.final !== 'undefined' &&  typeof a.final !== 'undefined')  {
                if (b.final.finalcourt == a.final.finalcourt) {                    
                    return b.final.score - a.final.score;
                } else {
                    return a.final.finalcourt - b.final.finalcourt;
                }
            } else if (typeof a.final !== 'undefined') {
                return -1
            } else if (typeof b.final !== 'undefined') {
                return 1
            } else {
                return b.totalscore - a.totalscore
            }        
        });
        return myplayers;
    },


    generateRound(playerspercourt, gametype, roundtype, finaltype, courts, candidatePlayers, historicalRounds, maxscore, sortbymethod) {
        console.log("generateRound");
        let round = {};        
        round.iscomplete = false;
        round.allscorereported = false;
        round.type = roundtype;
        round.maxscore = maxscore;
        round.idlescore = Math.ceil(maxscore / 2);    
        
        let isAmericanoPair = false;
        if (playerspercourt == 2) {
            isAmericanoPair = true;
        }
        let maxNbrPlayesCourtCapacity = playerspercourt * courts.length;  
        let possiblePlayerMaxLength = Math.floor(candidatePlayers.length / playerspercourt) * playerspercourt;
        let nbrOfWishedPlayers =  Math.min(maxNbrPlayesCourtCapacity, possiblePlayerMaxLength );


        let teammateHistory = {}
        teammateHistory = this.initteamMateObjectPlayerForAll(candidatePlayers, teammateHistory, isAmericanoPair)
        teammateHistory = this.preparePlayerTeammateHistory(historicalRounds, teammateHistory, isAmericanoPair);
        let categorizedPlayers = {}        
        let isAmericanoTeam = false;
    
        if (roundtype == 'groupplay') {    
            if (gametype == 'mexicano') {                
                let temp_categorizedPlayers = this.selectActivePlayersFromCandidatePlayer(candidatePlayers, teammateHistory, nbrOfWishedPlayers, isAmericanoTeam);
                categorizedPlayers = this.selectFinalOrMexicanoPlayersFromCandidatePlayer(roundtype, finaltype, temp_categorizedPlayers.activeplayers, nbrOfWishedPlayers, playerspercourt, sortbymethod)
                categorizedPlayers.idleplayers = temp_categorizedPlayers.idleplayers;
                round.teams = this.createTeamsFromArrayOfPlayersObject(categorizedPlayers.activeplayers, playerspercourt)
            } else if (gametype == 'americanoteam') {  
                isAmericanoTeam = true;              
                categorizedPlayers = this.selectActivePlayersFromCandidatePlayer(candidatePlayers, teammateHistory, nbrOfWishedPlayers, isAmericanoTeam);
                console.log("categorizedPlayers.activeplayers.length = " +categorizedPlayers.activeplayers.length)
                round.teams = this.generateTeamsLinearRandomAlgorith(categorizedPlayers.activeplayers, teammateHistory, isAmericanoTeam)                
            } else {
                categorizedPlayers = this.selectActivePlayersFromCandidatePlayer(candidatePlayers, teammateHistory, nbrOfWishedPlayers, isAmericanoTeam);
                round.teams = this.generateTeamsLinearRandomAlgorith(categorizedPlayers.activeplayers, teammateHistory, isAmericanoTeam)    
            }
        } else {            
            categorizedPlayers = this.selectFinalOrMexicanoPlayersFromCandidatePlayer(roundtype, finaltype, candidatePlayers, nbrOfWishedPlayers, playerspercourt, sortbymethod)
            round.teams = this.createTeamsFromArrayOfPlayersObject(categorizedPlayers.activeplayers, playerspercourt)
        }
        round.activeplayers = categorizedPlayers.activeplayers;
        round.idleplayers = categorizedPlayers.idleplayers;
        round.time = new Date();
        round.matches = [];                        
        
        for (let i = 0; i < round.teams.length/2; i++) {
            let homeT = round.teams[2 * i];
            let guestT = round.teams[2 * i + 1];
            let match = this.generateMatch(
                homeT,
                guestT,
                courts[i]
            );
            round.matches.push(match);
        }
        return round;
    },
    checkIfAllTeamsPlayedEqualManyRounds(candidatePlayers_arg) {
        let playedRounds = candidatePlayers_arg[0].numberOfplayedRounds;
        for (let i=1; i<candidatePlayers_arg.length; i++) {
            if (playedRounds != candidatePlayers_arg[i].numberOfplayedRounds) {
                return false;
            }
        }
        return true;
    },
    convertFromTeamToPlayer(arrayOfTeams) {
        let myPlayers = []
        for (let i=0; i<arrayOfTeams.length; i++) {
            myPlayers.push(arrayOfTeams[i].player1)
            if (arrayOfTeams[i].player2 !== null) {
                myPlayers.push(arrayOfTeams[i].player2)
            }
        }
        return myPlayers
    },
    findRestOfPlayers(bigList, smallerList) {  
        let restPlayers = []      
        for (let i=0; i<bigList.length; i++) {            
            let isFound = false;
            for (let j=0; j<smallerList.length; j++) {
                if (smallerList[j].id == bigList[i].id) {
                    isFound = true
                }
            }
            if (!isFound) {
                restPlayers.push(bigList[i])
            }
        }
        return restPlayers;
    },
    generateCharString(numberOfPlayers) {
        let charArray = "ABCDEFGHIJKLMNOPQRSTUVXYZÅÄÖ0123456789abcde";
        return charArray.substring(0, numberOfPlayers);
    },
    generateTeamsMexicanoAlgorith(activeplayers, candidatePlayers) {
        console.log(activeplayers)
        console.log(candidatePlayers)
        let candidatePlayersSorted = this.sortGroupGamesLeaderBoardTable(candidatePlayers, false)
        let mexicanoTeams = this.createTeamsFromArrayOfPlayersObject(candidatePlayersSorted, 4);
    
        console.log("MexicanoSortedTeams=" +JSON.stringify(mexicanoTeams))
        return mexicanoTeams;
    },

    

    generateTeamsLinearRandomAlgorith(players, teammateHistory, isAmericanoTeam) {    
        console.log("generateTeamsLinearRandomAlgorith  players.length = " + players.length)    
        players = players.sort(function (a, b) {
            return a.id - b.id;
        });
        let playerCharRepresentationString = this.generateCharString(players.length);
        let playerLetters = playerCharRepresentationString.split("");
        letterPlayerID_map = {};
        letterPlayerObjectMap = {};
        playerLetters.forEach((aMapLetter, index) => {
            letterPlayerID_map[aMapLetter] =  players[index].id
            letterPlayerObjectMap[aMapLetter] = players[index];
        });
        // MAIN CONFIGURE TO HIGH NUMBER CAN BE HEAVY
        let teamsPerCourt = 4        
        let numberofIteration = 2;
        let myTeamsString = "";
        if (players.length < 14) {
            numberofIteration = 7;
        } 
        if (isAmericanoTeam) {
            teamsPerCourt = 2            
            myTeamsString = this.linearRandomAlgorith(playerCharRepresentationString, teammateHistory, isAmericanoTeam);
            let teams = this.stringtoTeam(myTeamsString, isAmericanoTeam);
            let myTeams = this.convertStringrepresentationToTeams(teams, teamsPerCourt)     
            return myTeams;  
        } 

        let myTeamCandidates = []
        for (let i=0; i<numberofIteration; i++) {        
            myTeamsString = this.linearRandomAlgorith(playerCharRepresentationString, teammateHistory, isAmericanoTeam);
            let teams = this.stringtoTeam(myTeamsString, isAmericanoTeam);                        
            myTeamCandidates.push(this.shuffleOpponentsOptimizePlayedagainst(teams, teammateHistory))            
        }        
        let mySelectedTeamOpponentsData = this.selectTeamConstallation(myTeamCandidates)
        let mySelectedTeamOpponents = mySelectedTeamOpponentsData.teams
        let myTeams = this.convertStringrepresentationToTeams(mySelectedTeamOpponents, teamsPerCourt)        
        return myTeams;
    },    
    selectTeamConstallation(teamcandidatesData) {
        let maxCount = 10000;
        let averageMaxCount = 10000;
        let averageMinCount = 10000
        let index = 0;
        for (let i=0; i<teamcandidatesData.length; i++) {
            if (teamcandidatesData[i].maxCount <=maxCount) {
                maxCount=teamcandidatesData[i].maxCount
                if (teamcandidatesData[i].averageMaxCount <=averageMaxCount) {
                    averageMaxCount = teamcandidatesData[i].averageMaxCount
                    if (teamcandidatesData[i].averageMinCount <= averageMinCount) {
                        averageMinCount=teamcandidatesData[i].averageMinCount
                        index = i
                    }
                }
            }
        }
        console.log("found best teamconstellation at index="+index)
        return teamcandidatesData[index]
    },
    // take simpelTeams = "ACDBGHJL" as arg, and look up in letterPlayerObjectMap
    convertStringrepresentationToTeams(simpleTeams, playerspercourt) {
        let playerRepresentationString = "";
        for (let i=0; i<simpleTeams.length; i++) {
            playerRepresentationString += simpleTeams[i];
        }
        let mySortedPlayers = [];        
        let playerLetters = playerRepresentationString.split("");                
        playerLetters.forEach(playerLetter => {
            let player = letterPlayerObjectMap[playerLetter]
            mySortedPlayers.push(player)
        });
        let myTeams_temp = this.createTeamsFromArrayOfPlayersObject(mySortedPlayers, playerspercourt);
        return myTeams_temp;
    },
    createTeamsFromArrayOfPlayersObject(playersArray, playerspercourt) {
        console.log("createTeamsFromArrayOfPlayersObject")
        let myteams = [];
        let nbrTeams = Math.floor( playersArray.length  / (playerspercourt/2));
        for (let i = 0; i < nbrTeams; i++) {
            let myteam = {};            
            myteam.id = parseInt(uniqueId());
            myteam.isreported = false;
            myteam.player1 = playersArray[(playerspercourt /2) * i];
            if (playerspercourt == 2) {
                myteam.player2 = null;
                myteam.issingle = true;
            } else {
                myteam.player2 = playersArray[(playerspercourt /2) *i + 1];    
                myteam.issingle = false;
            }                                
            myteams.push(myteam);            
        }
        return myteams;
    },

    // Use this up to 9-20 player
    linearRandomAlgorith(string, teammateHistory, isAmericanoPair) {        
        let acceptNumberOfPlayWith = 0;    
        let factorials = []
        factorials[0] = 1;
        for (let i = 1; i <= string.length; i++) {
            factorials[i] = factorials[i - 1] * i;
        }
    
        while (acceptNumberOfPlayWith<30) {        
            let maxLoops = 30000;
            let nbrLoops = 0;
            //let playerHasPlayedBeforeHistory = {};        
            let i = Math.floor(Math.random() * (factorials[string.length] - 1));        
            while (nbrLoops < maxLoops) {
                i++
                let onePermutation = "";
                let temp = string;
                let positionCode = i;            
                let position = string.length;
                let playersPlayedBefore = false;
                nbrLoops++
                if (i % string.length == 0 ) {
                    i = Math.floor(Math.random() * (factorials[string.length] - 1));
                }
                while (position > 0 && !playersPlayedBefore) {
                    let selected = positionCode / factorials[position - 1];
                    onePermutation += temp.charAt(selected);
                    if (isAmericanoPair) {
                        playersPlayedBefore = this.hasTeamPlayedAmericanoPair(onePermutation, teammateHistory, acceptNumberOfPlayWith)    
                    } else {
                        playersPlayedBefore = this.hasTeamPlayed(onePermutation, teammateHistory, acceptNumberOfPlayWith)
                    }                    
                    if (playersPlayedBefore) {
                        i = Math.floor(Math.random() * (factorials[string.length] - 1));
                    }
                    positionCode = positionCode % factorials[position - 1];
                    temp = temp.substring(0, selected) + temp.substring(selected + 1);
                    position--
                }
                if (onePermutation.length == string.length && !playersPlayedBefore) {
                    console.log("Found result after nbrLoops=" + nbrLoops)
                    return onePermutation;
                }
            }
            console.log("")
            console.log("CAN not find result after nbrOfLoops= "+nbrLoops +"for acceptNumberOfPlayWith=" + acceptNumberOfPlayWith + "  So, increasing +1")
            console.log("")
            acceptNumberOfPlayWith++        
        }
    },
    hasTeamPlayedAmericanoPair(onePermutation, teammateHistory, acceptNumberOfPlayAgainst) {
        // let playedWithArray = [];
        let playedAgainstArray = [];
        if (onePermutation.length < 2) {
            return false;
        }
        let permutationCharArray = [] 
    
        permutationCharArray = onePermutation.split("");
        let p1_id;
        let p2_id;
        let nbrCompleteTeams = Math.floor((permutationCharArray.length) / 2);
        for (let i = 0; i < nbrCompleteTeams; i++) {
            let mapletter1 = permutationCharArray[2 * i]
            let mapletter2 = permutationCharArray[2 * i + 1]
            p1_id = letterPlayerID_map[mapletter1];
            p2_id = letterPlayerID_map[mapletter2];
            // if (typeof teammateHistory[p1_id] !== 'undefined' && typeof teammateHistory[p1_id]["playwith"] !== 'undefined' && typeof teammateHistory[p1_id]["playwith"][p2_id] !== 'undefined') {
            //     playedWithArray.push(teammateHistory[p1_id]["playwith"][p2_id]);
            // }
            if (typeof teammateHistory[p1_id] !== 'undefined' && typeof teammateHistory[p1_id]["playagainst"] !== 'undefined' && typeof teammateHistory[p1_id]["playagainst"][p2_id] !== 'undefined') {
                playedAgainstArray.push(teammateHistory[p1_id]["playagainst"][p2_id]);
            }
        }
        if (Math.max.apply(Math, playedAgainstArray) > acceptNumberOfPlayAgainst) {        
            return Math.max.apply(Math, playedAgainstArray);
        }
        return false;        
    },

    hasTeamPlayed(onePermutation, teammateHistory, acceptNumberOfPlayWith) {
        let playedWithArray = [];
        let playedAgainstArray = [];
        if (onePermutation.length < 2) {
            return false;
        }
        let permutationCharArray = [] 
    
        permutationCharArray = onePermutation.split("");
        let p1_id;
        let p2_id;
        let nbrCompleteTeams = Math.floor((permutationCharArray.length) / 2);
        for (let i = 0; i < nbrCompleteTeams; i++) {
            let mapletter1 = permutationCharArray[2 * i]
            let mapletter2 = permutationCharArray[2 * i + 1]
            p1_id = letterPlayerID_map[mapletter1];
            p2_id = letterPlayerID_map[mapletter2];
            if (typeof teammateHistory[p1_id] !== 'undefined' && typeof teammateHistory[p1_id]["playwith"] !== 'undefined' && typeof teammateHistory[p1_id]["playwith"][p2_id] !== 'undefined') {
                playedWithArray.push(teammateHistory[p1_id]["playwith"][p2_id]);
            }
            if (typeof teammateHistory[p1_id] !== 'undefined' && typeof teammateHistory[p1_id]["playagainst"] !== 'undefined' && typeof teammateHistory[p1_id]["playagainst"][p2_id] !== 'undefined') {
                playedAgainstArray.push(teammateHistory[p1_id]["playagainst"][p2_id]);
            }
        }
        if (Math.max.apply(Math, playedWithArray) > acceptNumberOfPlayWith) {        
            return Math.max.apply(Math, playedWithArray);
        }
        return false;
    },

    numberOfPlayedAgainst(team1, team2, teammateHistory) {
        let maximalMatches = -1
        let minimalMatches = 9999;
        //let playedAgainstResult = {};
        for (let k = 0; k < team1.length; k++) {
            for (let l = 0; l < team2.length; l++) {
                let nbrPlayedAgainst = teammateHistory[letterPlayerID_map[team1[k]]]["playagainst"][letterPlayerID_map[team2[l]]]
                if (nbrPlayedAgainst > maximalMatches) {
                    maximalMatches = nbrPlayedAgainst; 
                    //playedAgainstResult = {team1:team1, team2:team2, maximalMatches :maximalMatches  }
                }
                if (nbrPlayedAgainst < minimalMatches) {
                    minimalMatches = nbrPlayedAgainst;
                }
            }
        }
        return {maximalMatches:maximalMatches, minimalMatches:minimalMatches};
    },

    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    //
    shuffle(teams_arg) {
        let teams = JSON.parse(JSON.stringify(teams_arg));
        let newTeams = [];    
        newTeams.push(teams.shift())
        if (teams.length >1) {
            let pickThisRandomElement = this.randomInteger(1, teams.length-1)
            let tempTeam = teams.splice(pickThisRandomElement, 1)
            newTeams.push(tempTeam[0])
        }        
        while (teams.length > 0) {
            let pickThisRandomElement = this.randomInteger(0, teams.length-1)      
            let tempTeam = teams.splice(pickThisRandomElement, 1)
            newTeams.push(tempTeam[0])
        }
        return newTeams;
    },
    calcAverage(elmt) {
        var sum = 0;
        for( var i = 0; i < elmt.length; i++ ){
            sum += parseInt( elmt[i], 10 ); 
        }
        var avg = sum/elmt.length;
        return avg;
    },

    createPlayedAgainstMaximumObject(teams, teammateHistory) {
        if (teams.length % 2) {
            console.log("not even number");
            return {};
        }
        let maximalMatchesArray = [];
        let minimalMatchesArray = [];
        for (let i = 0; i < (teams.length/2); i++) {    
            let team1 = teams[2*i]
            let team2 = teams[2*i+1]
            let maximalMinimalMatches = this.numberOfPlayedAgainst(team1, team2, teammateHistory)     
            //maximalMatchesObj = {team1:team1, team2:team2, maximalMatches:maximalMatches}   
            maximalMatchesArray[2*i] = maximalMinimalMatches.maximalMatches
            maximalMatchesArray[2*i+1] = maximalMinimalMatches.maximalMatches
            minimalMatchesArray[2*i] = maximalMinimalMatches.minimalMatches
            minimalMatchesArray[2*i+1] = maximalMinimalMatches.minimalMatches
        }
        var maxCount = Math.max(...maximalMatchesArray);
        var averageMaxCount = this.calcAverage(maximalMatchesArray)
        var minCount = Math.min(...minimalMatchesArray);
        var averageMinCount = this.calcAverage(minimalMatchesArray);
        let teamsMaxNumberOfMatchesResult = {teams:teams, maximalMatches:maximalMatchesArray, maxCount:maxCount, averageMaxCount:averageMaxCount, minimalMatches:minimalMatchesArray, minCount:minCount, averageMinCount:averageMinCount}
        return teamsMaxNumberOfMatchesResult;
    },

    shuffleOpponentsOptimizePlayedagainst(teams_arg, teammateHistory) {
        let teams = JSON.parse(JSON.stringify(teams_arg));
        //if (teams.length < 3) {
            //return teams;
        //}
        let maximalMatchesArray = [];
        maximalMatchesArray.push(this.createPlayedAgainstMaximumObject(teams, teammateHistory))
        let shuffleLoopCount = 40
        for (let i=0; i<shuffleLoopCount; i++) {
            let nyNewTeams = this.shuffle(teams)
            maximalMatchesArray.push(this.createPlayedAgainstMaximumObject(nyNewTeams, teammateHistory))
        }        
        let findMinimum = 99999
        let findMinimumOfMinCount = 99999
        let avergeMinimum = 99999;
        let averageMaximum = 99999;
        let minimumPosition = 0;         
        for (let i=0; i<maximalMatchesArray.length; i++) {
            let tempMaxCount = maximalMatchesArray[i].maxCount
            if (tempMaxCount <= findMinimum) {
                findMinimum = tempMaxCount;
                let tempMinCount = maximalMatchesArray[i].minCount
                if (tempMinCount <= findMinimumOfMinCount) {
                    findMinimumOfMinCount = tempMinCount
                    let tempaverageMaxCount = maximalMatchesArray[i].averageMaxCount
                    if (tempaverageMaxCount <= averageMaximum) {
                        averageMaximum = tempaverageMaxCount;                        
                        let tempavergeMinimum = maximalMatchesArray[i].averageMinCount
                        if (tempavergeMinimum <= avergeMinimum) {                            
                            avergeMinimum = tempavergeMinimum;
                            minimumPosition = i;
                        }
                    }                    
                }                
            }
        }
        //console.log("Before shuffle teams---")     
        //this.debugPrintTeam(maximalMatchesArray[0])
        //console.log("After shuffle teams---")     
        //this.debugPrintTeam(maximalMatchesArray[minimumPosition])
        return maximalMatchesArray[minimumPosition]
    },

    debugPrintTeam(teamPlayedObject) {
        console.log('teams:' +teamPlayedObject.teams)
        let tempTeams = teamPlayedObject.teams
        let teamReadText = "teams: "
        for (let i=0; i<tempTeams.length; i++ ) {
            let team = tempTeams[i];            
            let player1 = letterPlayerObjectMap[team.substring(0,1)].name
            teamReadText += player1 + " + "
            let player2 = letterPlayerObjectMap[team.substring(1,2)].name
            teamReadText += player2 + ",  "
        }
        console.log(teamReadText)
        console.log('maximalMatches:' +teamPlayedObject.maximalMatches)
        console.log('minimalMatches:' +teamPlayedObject.minimalMatches)
    },

    selectFinalOrMexicanoPlayersFromCandidatePlayer(roundtype, finaltype, players_arg, nbrOfPlayers, playerspercourt, sortbymethod) {        
        console.log("selectFinalOrMexicanoPlayersFromCandidatePlayer")
        
        let players_arg_notIdle = players_arg.filter(function (el){
            return (!el.forceidle)
        });
        console.log("players_arg_notIdle length=" +players_arg_notIdle.length)

        let activeplayers = []
        let idleplayers = [];
        let candidatePlayers = this.sortGroupGamesLeaderBoardTable(players_arg_notIdle, false, sortbymethod) 
        //default one_three. If group play, alway one_four to even out skil level
        let pickOrder = [0,2,1,3];
        if (playerspercourt == 2) {
            pickOrder = [0,1]; 
        } else if (roundtype == 'groupplay') {
            pickOrder = [0,3,1,2];
        } else if (finaltype == 'one_two') {
            pickOrder = [0,1,2,3]; 
        } else if (finaltype == 'one_four') {
            pickOrder = [0,3,1,2];
        } 
        for (let i=0; i<nbrOfPlayers/playerspercourt; i++) {
            for (let j=0; j<pickOrder.length; j++) {
                let pos = playerspercourt*i+pickOrder[j];                
                activeplayers.push(candidatePlayers[pos])
            }
        }
        if (candidatePlayers.length > nbrOfPlayers) {
            console.log("Some can not play. Too low score")
            let nbrIdlePlayers = candidatePlayers.length - nbrOfPlayers;
            for (let i=0; i<nbrIdlePlayers; i++) {
                idleplayers.push(candidatePlayers[nbrOfPlayers+i])                
            }
        }
        return { activeplayers: activeplayers, idleplayers: idleplayers }
    },

    selectActivePlayersFromCandidatePlayer(candidatePlayers, teammateHistory, nbrOfPlayers, isAmericanoTeam) {
        console.log("selectActivePlayersFromCandidatePlayer")
        candidatePlayers.forEach(candidatePlayer => {
            let lateArrivalAdjustment = 0;
            if (candidatePlayer.latearrival > 0) {
                let shareOfActiveRounds = teammateHistory.totalActive / (teammateHistory.totalActive + teammateHistory.totalIdle)
                lateArrivalAdjustment = Math.ceil(shareOfActiveRounds * candidatePlayer.latearrival)
            }
            let tempActiveRounds = 0;
            let templastPlayedRoundindex = -1;
            if (typeof teammateHistory[candidatePlayer.id] !== 'undefined' && typeof teammateHistory[candidatePlayer.id].activeRounds !== 'undefined') {
                tempActiveRounds = teammateHistory[candidatePlayer.id].activeRounds.length;
                // if new arrival, emtpy activeRounds This force new player to be prio for net round
                if (teammateHistory[candidatePlayer.id].activeRounds.length == 0) {
                    templastPlayedRoundindex = 0;
                } else {
                    templastPlayedRoundindex = Math.max.apply(Math, teammateHistory[candidatePlayer.id].activeRounds)
                }                
            }
            candidatePlayer.numberOfplayedRounds = lateArrivalAdjustment + tempActiveRounds;
            candidatePlayer.lastPlayedRoundindex = templastPlayedRoundindex;
            //ToDO force idle . add attribute forceIdle and put those at end of sort
        });
        candidatePlayers = candidatePlayers.sort(function (a, b) {
            if(typeof b.forceidle !== "undefined" && b.forceidle) {
                return -1
            }
            if(typeof a.forceidle !== "undefined" && a.forceidle) {
                return 1
            }

            if (b.numberOfplayedRounds - a.numberOfplayedRounds == 0) {
                if (b.lastPlayedRoundindex - a.lastPlayedRoundindex == 0) {
                    let myRandom = Math.sign((Math.random() * 1) - 0.5)
                    return myRandom
                }
                return a.lastPlayedRoundindex - b.lastPlayedRoundindex
            } else {
                return a.numberOfplayedRounds - b.numberOfplayedRounds;
            }
        });
        let activeplayers = candidatePlayers.slice(0, nbrOfPlayers);
        let idleplayers = candidatePlayers.slice(nbrOfPlayers);
        //IF activePlayers == idleplayers we fail in static loop. Shuffle some players    
        //IF 4 teams in teams with 1 court, we not have problem
        let allPlayedSameNbrRounds = this.checkIfAllTeamsPlayedEqualManyRounds(candidatePlayers);
        let shareOfOddIdle =     (Math.abs(idleplayers.length - activeplayers.length)) / activeplayers.length        
        let treasholdToForceShuffle = 1/4;        

        // IF all on equal rounds-Rely on the linearAlgorith NOT on latest round. Could be better... @todo
        if (allPlayedSameNbrRounds && isAmericanoTeam) {
            console.log("All played equal. Do not consider lastplayed")               
            let tempTeams = this.generateTeamsLinearRandomAlgorith(candidatePlayers, teammateHistory,"???")                        
            activeplayers = this.convertFromTeamToPlayer( tempTeams.slice(0,nbrOfPlayers) )
            idleplayers = this.findRestOfPlayers(candidatePlayers, activeplayers)            
        } else if (shareOfOddIdle < treasholdToForceShuffle) {
            let roundTrigger = 2
            if ( (idleplayers.length>2) && (activeplayers.length>2) ) {            
                roundTrigger = 3
            }
            if ((Math.floor((teammateHistory.rounds+1) % roundTrigger) == 0)  &&   (idleplayers.length>1) && (activeplayers.length>1) ) {
                let numberOfPlayersToSwop = Math.floor(activeplayers.length/4)
                // if (numberOfPlayersToSwop == 0){
                //     numberOfPlayersToSwop = 1;
                // }
                console.log("FORCE ReOrganize Idle Players, swop "+numberOfPlayersToSwop)
                let removeFromIdle = [];
                let removeFromActive = [];
                for (let i=0; i<numberOfPlayersToSwop; i++) {
                    removeFromIdle.push(idleplayers.shift())                    
                    removeFromActive.push(activeplayers.pop())
                }
                for (let i=0; i<numberOfPlayersToSwop; i++) {
                    idleplayers.push(removeFromActive[i])
                    activeplayers.push(removeFromIdle[i])
                } 
            }
        }




        return { activeplayers: activeplayers, idleplayers: idleplayers }
    },

    preparePlayerTeammateHistory(historicalRounds, teammateHistory, isAmericanoPair) {
        console.log("Enter preparePlayerTeammateHistory")        
        historicalRounds.forEach((round, roundindex) => {
            round.idleplayers.forEach(idleplayer => {
                teammateHistory = this.initteamMateObjectPlayer(idleplayer, teammateHistory, isAmericanoPair);
                teammateHistory[idleplayer.id]["idleRounds"].push(roundindex);
            })
            let matches = round.matches;
            matches.forEach(match => {
                teammateHistory = this.initPlayersInTeam(match.teams, teammateHistory, isAmericanoPair);
                teammateHistory = this.updateteamMateHistory(match.teams[0], match.teams[1], teammateHistory, roundindex, isAmericanoPair)
                teammateHistory = this.updateteamMateHistory(match.teams[1], match.teams[0], teammateHistory, roundindex, isAmericanoPair)
            });
            
        });
        //update the average IdleRatio
        let maxNumberOfPlayedRounds = -1;
        let maxNumberOfIdleRounds = -1;
        let totalActive = 0;
        let totalIdle = 0;
        const keys = Object.keys(teammateHistory);
        keys.forEach(key => {
            maxNumberOfPlayedRounds = Math.max(teammateHistory[key].activeRounds.length, maxNumberOfPlayedRounds)
            maxNumberOfIdleRounds = Math.max(teammateHistory[key].idleRounds.length, maxNumberOfIdleRounds)
            totalActive += teammateHistory[key].activeRounds.length;
            totalIdle += teammateHistory[key].idleRounds.length;
        });
        teammateHistory.maxNumberOfPlayedRounds = maxNumberOfPlayedRounds;
        teammateHistory.maxNumberOfIdleRounds = maxNumberOfIdleRounds;
        teammateHistory.totalActive = totalActive;
        teammateHistory.totalIdle = totalIdle;
        teammateHistory.rounds = historicalRounds.length;
        return teammateHistory;
    },
    initteamMateObjectPlayerForAll(playerList, teammateHistory, isAmericanoPair) {        
        playerList.forEach(player => {
            teammateHistory = this.initteamMateObjectPlayer(player, teammateHistory, isAmericanoPair)
        });
        for (let i = 0; i < playerList.length; i++) {
            for (let j = 0; j < playerList.length; j++) {
                if (! (i == j)) {      
                    if (!isAmericanoPair) {
                        if (typeof teammateHistory[playerList[i].id]["playwith"][playerList[j].id] == 'undefined') {
                            teammateHistory[playerList[i].id]["playwith"][playerList[j].id] = 0;
                        }
                    }
                    if (typeof teammateHistory[playerList[i].id]["playagainst"][playerList[j].id] == 'undefined') {
                        teammateHistory[playerList[i].id]["playagainst"][playerList[j].id] = 0;
                    }
                }
            }
        }
        return teammateHistory;
    },
    
    initteamMateObjectPlayer(player, teammateHistory, isAmericanoPair) {
        if (player!== null && typeof teammateHistory[player.id] == 'undefined') {
            teammateHistory[player.id] = {};
            if (!isAmericanoPair) {
                teammateHistory[player.id].playwith = {};
            }            
            teammateHistory[player.id].playagainst = {};
            teammateHistory[player.id].activeRounds = [];
            teammateHistory[player.id].idleRounds = [];
        }
        return teammateHistory;
    },

    initPlayersInTeam(teams, teammateHistory, isAmericanoPair) {
        teams.forEach(team => {
            teammateHistory = this.initteamMateObjectPlayer(team.player1, teammateHistory, isAmericanoPair);
            teammateHistory = this.initteamMateObjectPlayer(team.player2, teammateHistory, isAmericanoPair);
        });
        return teammateHistory;
    },

    updateteamMateHistory(team1, team2, teammateHistory, roundindex, isAmericanoPair) {
        teammateHistory[team1.player1.id]["activeRounds"].push(parseInt(roundindex));
        teammateHistory[team1.player1.id]["playagainst"][team2.player1.id] += 1;

        if (!isAmericanoPair) {
            teammateHistory[team1.player2.id]["activeRounds"].push(parseInt(roundindex));
            teammateHistory[team1.player1.id]["playwith"][team1.player2.id] += 1;
            teammateHistory[team1.player1.id]["playagainst"][team2.player2.id] += 1;
            teammateHistory[team1.player2.id]["playwith"][team1.player1.id] += 1;
            teammateHistory[team1.player2.id]["playagainst"][team2.player1.id] += 1;
            teammateHistory[team1.player2.id]["playagainst"][team2.player2.id] += 1;
        }
        return teammateHistory;
    },
    generateMatch(homeTeam, guestTeam, court) {
        let match = {};
        let myId = parseInt(uniqueId());
        match.id = myId;
        match.court = court;
        match.teams = [homeTeam, guestTeam];
        return match;
    },
    teamsToString(arrayOfTeams) {
        let str = "";
        for (let i = 0; i < arrayOfTeams.length; i++) {
            str = str + arrayOfTeams[i];
        }
        return str;
    },
    stringtoTeam(str, isAmericanoTeams) {        
        let nbrOfindividuals = 4;
        if (isAmericanoTeams) {
            nbrOfindividuals = 2;
        }
        let charArray = str.split("");
        let myNbr = Math.floor(charArray.length / nbrOfindividuals)
        let myTeams = []
        for (let i = 0; i < myNbr; i++) {            
            let team1 = charArray[i * nbrOfindividuals] + charArray[i * nbrOfindividuals + 1]
            let team2 = charArray[i * nbrOfindividuals + 2] + charArray[i * nbrOfindividuals + 3]
            if (isAmericanoTeams) {
                team1 = charArray[i * nbrOfindividuals]
                team2 = charArray[i * nbrOfindividuals + 1]
            }
            myTeams.push(team1)
            myTeams.push(team2)
        }
        return myTeams;
    },
    

    //TOUR
    getTourRoundObject(tournament) {
        let tourround = {};
        tourround.date = tournament.createat;
        tourround.type = tournament.type
        tourround.maxscore = tournament.maxscore
        tourround.finaltype = tournament.finaltype
        tourround.tourplayers = this.getTourPlayers(tournament.players)
        return tourround;
    },
    getTourPlayers(players) {        
        let tourplayers = JSON.parse(JSON.stringify(players))
        let maxScore = tourplayers.length;
        for (let i=0; i<tourplayers.length; i++) {
            //let tourplayer = JSON.parse(JSON.stringify(players[i]))
            delete tourplayers[i].lastPlayedRoundindex
            delete tourplayers[i].numberOfplayedRounds
            delete tourplayers[i].position
            delete tourplayers[i].scores  
            tourplayers[i].tourscore = maxScore-i;
            // tourplayers.push(tourplayer);
        }
        return tourplayers
    },
    // date:
    // type: americano, mexicano, americanoteam, tour
    // maxscore: Number
    // finaltype: one_three, one_four, one_two
    // tourplayers: 
    //    name //inherit
    //    totalscore //inherit
    //    final //inherit
    //    position
    //    tourscore (1..maxPlayersInTournament)

    generateTourRoundFromRound(tournament) {
        let tourround = {};
        tourround.createat = tournament.createat;
        tourround.name = tournament.name
        tourround.type =  tournament.type
        tourround.maxscore =  tournament.maxscore
        tourround.finaltype =  tournament.finaltype
        let isForFinalBoard = true;
        let tournamentPlayers = this.sortGroupGamesLeaderBoardTable(tournament.players, isForFinalBoard)
        tourround.tourplayers = this.generateTourPlayers(tournamentPlayers)        
        return tourround;
    },
    generateTourPlayers(players) {
        console.log("generateTourPlayers")
        let result = [];
        let maxScore = players.length;
        for (let i=0; i<players.length; i++) {
            let tourplayer = {};
            tourplayer.name = players[i].name;
            tourplayer.id = players[i].id;
            tourplayer.specificgameposition = i+1;
            tourplayer.tourscore = maxScore-i
            result.push(tourplayer)
        }
        return result;
    },
    getUniqueIdWithDate() {
        console.log("getUniqueIdWithDate");
        var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        
        let prefix = "" +year +month + day
        prefix = prefix.substring(4,8)
        let tempIsStr = "" +prefix + uniqueId() + this.getRandomintString(4);
        return parseInt(tempIsStr);
    },
    getRandomintString(length) {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


}