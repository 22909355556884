<template>  
  <div class="team">
    
    <b-card-group deck class="playercardgroup">      
      <b-card  bg-variant="light" align="center" style="max-width: 85%, padding:0rem" class="playercard">        
      <template #header>
        <h3 class="mb-0 nowrap" >{{player1.name}}</h3>
      </template>
        <b-card-text v-if="Number.isInteger(player1.position[player1.position.length-1]) && showPositionAndTrend">                         
          <b><b-badge style="color: black; background-color:#F0F0F0;font-size:1.1rem" >#{{player1.position[player1.position.length-1]+1}}</b-badge></b>          
          <b-icon-arrow-up-circle-fill v-if="player1trend > 2" font-scale="2" style="color: #107910; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-up-circle-fill>
          <b-icon-arrow-up-right-circle-fill v-else-if="player1trend > 0" font-scale="2" style="color: #67ff67; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-up-right-circle-fill>
          <b-icon-arrow-right-circle-fill v-else-if="player1trend == 0"  font-scale="2" style="color: #FFCC00;margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-right-circle-fill>
          <b-icon-arrow-down-right-circle-fill v-else-if="player1trend < -2"  font-scale="2" style="color: #0099FF; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-down-right-circle-fill>
          <b-icon-arrow-down-circle-fill v-else-if="player1trend < 0"  font-scale="2" style="color: #0000FF; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-down-circle-fill>
        </b-card-text>
      </b-card>
      <div class="roundbuttoncontainer">
        <button v-bind:class="[myclassobject, myclassobject2]" class="roundbutton" v-on:click="showModal" >        
          <div v-if="myScore >= 0"> <b>{{ myScore }}</b></div>
          <div v-else>
            <b-icon-question-circle  scale="2" animation="throb" ></b-icon-question-circle>           
          </div>
        </button>
      </div>

      
      <b-card v-if="player2 !== null" bg-variant="light" align="center" style="max-width: 85%, padding:0rem" class="playercard">
        <template #header>
          <h3 class="mb-0 nowrap">{{player2.name}}</h3>
        </template>        
        <b-card-text v-if="Number.isInteger(player2.position[player2.position.length-1]) && showPositionAndTrend"> 
          <b><b-badge style="color: black; background-color:#F0F0F0;font-size:1.1rem" >#{{player2.position[player2.position.length-1]+1}}</b-badge></b>          
          <b-icon-arrow-up-circle-fill v-if="player2trend > 2" font-scale="2" style="color: #107910; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-up-circle-fill>
          <b-icon-arrow-up-right-circle-fill v-else-if="player2trend > 0" font-scale="2" style="color: #67ff67; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-up-right-circle-fill>
          <b-icon-arrow-right-circle-fill v-else-if="player2trend == 0"  font-scale="2" style="color: #FFCC00;margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-right-circle-fill>
          <b-icon-arrow-down-right-circle-fill v-else-if="player2trend < -2"  font-scale="2" style="color: #0099FF; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-down-right-circle-fill>
          <b-icon-arrow-down-circle-fill v-else-if="player2trend < 0"  font-scale="2" style="color: #0000FF; margin-left:0.3rem; vertical-align:-0.3em;"></b-icon-arrow-down-circle-fill>
        </b-card-text>
      </b-card>
    </b-card-group>

    <modal-component
      v-if="showModalState"
      @close="showModalState = false"
      :scoreset="myScoreSet"
      @submit:matchscore="reportmatchscore"
    >
    </modal-component>
  </div>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
//import uniqueId from "lodash.uniqueid";
export default {
  components: {
    ModalComponent,
  },

  props: {
    player1: { required: true, type: Object },
    player2: { required: false, type: Object },
    id: { required: true, type: Number },
    matchid: { required: true, type: Number },
    myScore: { required: false, type: Number },
    maxscore: { required: false, type: Number },
    iscomplete: {required: false, type: Boolean},
    myreactor:  { required: false, type: Number },
    showPositionAndTrend : {required: false, type: Boolean, default: true},
  },
  data() {
    return {
      thePoints: '',
      showModalState: false,      
      animationActive:false,

    };
  },
  methods: {
    showModal() {      
      if (!this.iscomplete) {
        this.showModalState = true;
      }
      
    },
    reportmatchscore(score) {
      //console.log("reportmatchscore()")      
      this.thePoints = score;      
      this.$emit("submit:points", this.id, score, this.matchid);
      this.showModalState = false;
    },
  },
  computed: {
    myScoreSet: function () {
      let myScoreArray = [];
      for (let i = 0; i <= this.maxscore; i++) {
        myScoreArray.push(i);
      }
      return myScoreArray;
    },
    player1trend: function () {      
      if (this.player1.position.length>1) {
        let theLength = this.player1.position.length;      
        let trend = this.player1.position[theLength-2] - this.player1.position[theLength-1];      
        return trend;
      }
      return 0;
    },
     player2trend: function () {
      if (this.player2.position.length>1) {
        let theLength = this.player2.position.length;
        let trend = this.player2.position[theLength-2] - this.player2.position[theLength-1];
        return trend;
      }
      return 0;
    },    
    myclassobject2 : function() {     
      if (this.animationActive) {
        return "animateres"
      }  else {
        return "notanimateres";        
      }
    },

    myclassobject : function() {      
      // console.log("EDIT CLASSOBJECT for RoundedButton")
      if (this.iscomplete) {
        return "scorebuttonlocked";
      }
      if (this.myScore >=0 ) {
        return "scorebuttonhasvalue";
      }else {
        return "scorebuttonwaiting animateWait"
      }

    }
    
  },
  watch: {
    myScore: function (newVal) {
      // console.log("COMPONENT TEAMDISPLAY . WATCH myScore prop changed: ", newVal, " | was: ", oldVal);      
      if (!isNaN(newVal)) {
        this.animationActive = true;
        setTimeout(() => {
              this.animationActive = false;
          }, 3000);       
      }

      this.thePoints = newVal;
    },
  },
};
</script>


<style scoped>

.b-icon.bi {
/* svg { */
  vertical-align:-0.3 em;
  /* margin-left: 2rem; */
}
.myspan {  
  padding-right: 10px;
}

.card-header {
  padding: 0.1rem;
}
.card-text {
  padding: 0.1rem;
}
.card-body {
  padding: 0.2rem;
}
.nowrap {
  white-space: nowrap;
}
.playercardgroup {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 1rem; */
}

.playercard {
  border: 2px solid black;
  min-width: 100%;
  margin: 0.5rem 0 0.5rem 0;
}

.roundbuttoncontainer {
  height: 4rem;
}

.animateWait {
  animation: waitanimation 5s linear 10; 
  animation-delay: 7s 
}
@keyframes waitanimation {
  0% {        
    border: 2px solid blue;
    background-color: blue;       
  }
  4% {        
    border: 2px solid black;
    background-color: lightgray;           
  }
  6% {        
    border: 2px solid blue;
    background-color: blue;       
  }
  10% {        
    border: 2px solid black;
    background-color: lightgray;           
  }
  
}

.notanimateres {
  font-size: 16px; 
}

.animateres {  
  /* animation: myanimation 1s ease-in-out 4; */
  animation: myanimation 1s linear 4;  
}
@keyframes myanimation {
  0% {    
    opacity: 1;
    /* width: 100%;
    height: 100%;
    font-size: 16px; */
  }
  60% {
    opacity: 1;
    /* background-color: lightgreen; */
    /* width: 100%;
    height: 100%;
    font-size: 25px; */
  }

  70% {
    opacity: 1;
    /* background-color: lightgreen; */
    /* width: 100%;
    height: 100%;
    font-size: 25px; */
  }
  71% {
    opacity: 0;
    /* width: 100%;
    height: 100%;
    font-size: 25px; */
  }
  100% {
    opacity: 0;
    /* width: 100%;
    height: 100%;
    font-size: 16px; */
  }
}

scorebuttonwaiting:hover {
  background-color: red;  
}

.scorebuttonwaiting {  
  border: 2px solid black;
  background-color: lightgray;   
  
  /* transition: background-color 2s; */
}

.scorebuttonhasvalue {
  background-color: lightgreen;
  border: 2px solid black;
  /* font-size: 16px; */
  /* transition: background-color 2s; */
}

.scorebuttonlocked {
  border: 3px solid red;
  background-color: lightgreen;
  /* font-size: 16px; */
  /* background-color: red; */
}

.roundbutton {  
  /* border: none; */
  /* border: 2px solid black; */
  color: black;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  
  border-radius: 50%;
  margin: 0;
  transition: background-color 2s, font-size 2s;  
}

.card-header {  
  padding:0.2rem;
}

</style>