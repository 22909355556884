import Vue from 'vue'
import App from './App.vue'

// import axios from 'axios'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueCookies from 'vue-cookies';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCookies)


Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')
