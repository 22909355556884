<template>
  <div>
    <b-modal
      id="modal-register-tour"
      ref="registertourmodal"
      title="CreateNew/Select Tour"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-title="selectedName"
      :ok-disabled="!(this.selected.length > 0) || tournamentisstarted"
    >
    <h3> Pick from existing tour </h3>  
     <div>
        <b-table striped hover selectable   selected-variant="primary" ref="selectableTable" @row-selected="onRowSelected" select-mode="single" :items="myTours" :fields="displayFields">
        <template #cell(details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">              
            {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
            </b-button>
        </template>

        <template #cell(Select)="row">
            <b-button size="sm"  @click="selectthis(row.index)" class="mr-2" variant="outline-primary">
            Select 
            </b-button>
        </template>


        <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right" cols="6"><b>Rounds:</b></b-col>
                  <b-col cols="6">{{row.item.tourrounds.length}} </b-col>                
              </b-row>
              <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right" cols="6"><b>Time:</b></b-col>
                  <b-col cols="6">{{formatDate(row.item.time)}} </b-col>                
              </b-row>
              <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Players:</b></b-col>
                  <b-col>{{getPlayersString(row.item.tourplayershash) }} </b-col>                
              </b-row>
              <b-button size="sm" @click="showTourStandings(row.index)"  variant="outline-secondary"> {{tourstandingsbuoondisplay}} Tour standings</b-button>
              <b-button size="sm" @click="deletethis(row.index)" class="somemarginleft" variant="outline-danger">
                Delete Tour
              </b-button>

                            
              <tourstandings-table      
                v-if="showtourstanding"                                  
                :tourObject="getTourObject(row.index)"
              />
            </b-card>
        </template>        

        </b-table>
    </div>

    <h3 class="createnewtourlabel"> Create a new Tour</h3>    
         
      <form ref="form" @submit.stop.prevent="handleSubmit">


        <b-form-group label="Tour-Name" label-for="tour-name"                
        >
            <b-form-input
            id="tour-name"
            v-model="form.tourname"
            autocomplete="off"
            type="text"
            placeholder="Ladies Open April 2022"
            :state="validTourname2"            
            required
            >                                
            </b-form-input>    
             <b-form-invalid-feedback :state="validTourname2">
              {{validTournameMessage}}
            </b-form-invalid-feedback>        
        </b-form-group>
        
        
                
      </form>
      <b-button variant="primary" :disabled="newTourButtondisabled" @click="handleRegusterNewTour()" class="somemargin">New Tour</b-button>        

    </b-modal>
  </div>
</template>
     

<script>
import RestService from '@/services/RestService.js'
import TourstandingsTable from "./TourstandingsTable.vue";


export default {
  name: "register-tour",
  components: {
    TourstandingsTable
  },

  data() {
    return {
        form: {                    
          tourname:'',  
          email:this.email,  
        },
        // validtourname:null,                
        myTours:[],
        displayFields:[{ key: 'tourname', label: 'Tour Name' },  'details', 'Select'],
        selected:[],
        selectedIndex : -1,
        selectedName : "Select a tour",
        selectedTour:{},
        showtourstanding:false,
        VUE_APP_USEAMERICANOTOUR:false,        
        
    };
  },
  methods: {
    checkFormValidity() {
      console.log("checkFormValidity")
      // this.validtourname =  (this.form.tourname.length > 3 && this.form.tourname.length < 20)
      return this.validtourname2
    },
    getPlayersString(playersHash) {
      let playerString = "";
      if (typeof playersHash !== 'undefined') {
        let pArray = Object.values(playersHash)
        pArray.sort();
        
        for (let i=0; i<pArray.length; i++) {
          playerString += pArray[i];
          if (i<(pArray.length-1)) {
            playerString += ", "  
          }        
        }
      }
      return playerString;
    },

    resetModal() {
      this.form.tourname = "";
    },
    handleOk(bvModalEvt) {
      console.log("handle handleOk")
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      //this.handleSubmit();
      // Emit the Selected Tour
      //this.$emit("selectedtour", this.myTours[this.selectedIndex]._id);
      this.$emit("selectedtour", this.myTours[this.selectedIndex]);
      this.$nextTick(() => {
        this.$bvModal.hide("modal-register-tour");
      });   


    },
    handleSubmit() { 
      return ;
    },
    // async handleSubmit() {
    async handleRegusterNewTour() {
      // Exit when the form isn't valid
      console.log("handle submit")
      this.form.email = this.email
      console.log(JSON.stringify(this.form))
      console.log("this.validtourname2 = " + this.validtourname2)
      if (!this.getValidTourName(this.form.tourname)) {
        console.log("WILL RETURN ")
        return ;
      }
      
      try {
        let mytoken = this.$cookies.get('lop-jwt');
        this.form.tourrounds = [];
        let responseTour = await RestService.postNewTour(this.form, mytoken)            
        console.log("registerTour response"+JSON.stringify(responseTour.data))
        this.loadMyTours()
        this.form.tourname = "";
       } catch (error) { 
         console.log("Can not create TOUR user")
       }                  
      this.$nextTick(() => {
        // TODO this.$bvModal.hide("modal-register-tour");
      });
    },
    async loadMyTours() {
      try {
        let mytoken = this.$cookies.get('lop-jwt');
        let responseMyTours = await RestService.getTours(mytoken)            
        //console.log("responseMyTours response"+JSON.stringify(responseMyTours.data))
        this.myTours = responseMyTours.data
       } catch (error) { 
         console.log("Can not loadMyTours")
       }            
    },
    formatDate(date) {
        return date.substring(0,10)
    }, 
    selectthis(index) {
        console.log("selectthis index=" + index)
        this.$refs.selectableTable.selectRow(index)
        this.selectedIndex = index;        
        this.selectedName  ="Start Tour " +this.myTours[index].tourname     
    },
    onRowSelected(items) {
        console.log("onRowSelected. items="+items)        
        if (items.length == 0) {
            console.log("NO items selected")
            this.selectedName  ="No Tour selected" 
        } else {
            this.selectedName  ="Start Tour " +items[0].tourname
        }
        this.selected = items
    },
    showTourStandings(aIndex) {
      this.showtourstanding = !this.showtourstanding
      console.log("showTourStandings SET selectedTour" +aIndex)      
      this.selectedTour = this.myTours[aIndex]
      console.log("this.selectedTour = " +this.selectedTour.tourname);
    },
    getTourObject(indexNumber) {
      console.log("getTourObject indexNumber " +indexNumber);      
      return this.myTours[indexNumber]
    },
    getValidTourName(aFormName) {
      if (aFormName.length ==0 ||  (aFormName.length > 3 && aFormName.length < 20)) {
        return true;        
      } 
      return false;
    },
    async deletethis(aIndex) {
      try {
        let mytoken = this.$cookies.get('lop-jwt');
        let deleteThisTour  = this.myTours[aIndex]
        let rowId = deleteThisTour._id
        console.log("aRow ID " +rowId )
        let responseDeleteTour = await RestService.deleteTour(mytoken, rowId)       
        this.loadMyTours()     
        console.log("responseDeleteTour"+ responseDeleteTour.data)
       } catch (error) { 
         console.log("Can not delete tour")
       }                  
    }


  },


  props: {
    email:String,
    tournamentisstarted:Boolean,
    
  },
  computed: {
    tourstandingsbuoondisplay: function() {      
      return this.showtourstanding ? "Hide" :"Display"
    },
    newTourButtondisabled() {
      if (this.form.tourname.length <4) {        
        return true;
      } else if (this.form.tourname.length > 19) {        
        return true;
      }  else {
        return false;
      }
    },
    validTourname2() {    
      return this.getValidTourName(this.form.tourname);
      // if (this.form.tourname.length ==0 ||  (this.form.tourname.length > 3 && this.form.tourname.length < 20)) {
      //   return true;        
      // } 
      // return false;
    },
    validTournameMessage() {
      if (this.form.tourname.length>0 && this.form.tourname.length < 4 ) {
        return "Toruname must be little longer" 
      } else if (this.form.tourname.length > 19 ) { 
        return "Oooops, too long name" 
      } else  {
        return ""
      }

      
        

    }

  },
  mounted () {
    console.log("MOUNTING REGISTER TOUR")
    this.loadMyTours();                            
    if (process.env.VUE_APP_USEAMERICANOTOUR == 'true') {
        console.log("MOUNTED in Static infor VUE_APP_USEAMERICANOTOUR is TRUE")
        this.VUE_APP_USEAMERICANOTOUR = true;
    }
  },

  watch: {},
};
</script>

<style scoped>
  .createnewtourlabel {
    margin-top: 3rem;
  }
  .somemargin {
    margin-top: 0.5rem;
  }
  .somemarginleft {
    margin-left: 1rem;
  }

</style>