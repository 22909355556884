<template>
  <div>
    <b-modal
      id="modal-register-user"
      ref="modal"
      :title="title"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      @cancel="handleCancel"
      @close="handleCancel"
      :cancel-title="canceltitle"
      :ok-title="oktitle"
      :ok-disabled="disableOkButton"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group          
          label="Email"
          label-for="email-input"
          invalid-feedback="Email is required"
          :state="emailState"
        >          
          <b-form-input
            type="email"
            id="email-input"
            v-model="form.email"
            :state="emailState"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group  v-if="!(this.registerUserMode =='renewpasswordrequest')"  label="Password" label-for="password-input"
        :invalid-feedback="invalidpasswordfeedback"
        :state="passwordState"
        >
            <b-form-input
            id="password-input"
            v-model="form.pwd"
            type="password"
            placeholder="******"
            required
            ></b-form-input>
        </b-form-group>
                
        <b-form-group v-if="this.registerUserMode =='register'" label="Phone" label-for="phone-input"
        invalid-feedback="Phonenumber is required"
        :state="phoneState"
        >
            <b-form-input
            id="phone-input"
            v-model="form.phone"
            type="number"
            placeholder="0760123456"
            required
            ></b-form-input>
        </b-form-group>
      </form>

      
      <span v-if="this.registerUserMode =='login'" class="myregister">
          Not registered yet?            
          <span style="color:blue" @click="switchtype" >-Register</span>           
      </span>

      <div v-if="this.registerUserMode =='login'" class="myregister">           
          <span style="color:blue" @click="forgotpwd" >-Forgot password</span>
      </div>

      <span  v-if="this.registerUserMode =='register'" class="myregister">
          Already member. Take me to 
          <span style="color:blue" @click="switchtype" >-Login</span>           
      </span>


      <b-alert v-if="showErrorMessage" show variant="danger">
        {{errorMessage}}        
      </b-alert>


      
      <b-alert v-if="showSuccessMessage" show variant="success">        
        <b-icon icon="check-circle" font-scale="2"  variant="success"></b-icon>
        {{successMessage}}
      </b-alert>               
    </b-modal>
  </div>
</template>
     

<script>
import RestService from '@/services/RestService.js'
import VueCookies from 'vue-cookies'

//registerUserMode:"login, register, setnewpassword, renewpasswordrequest"
export default {
  name: "register-user",

  data() {
    return {
        form: {
          email: '',
          pwd: '',
          tourname:'',  
          phone:'',  
        },
        // email: '',
        emailState: null,
        passwordState:null,
        phoneState:null,        
        invalidpasswordfeedback: "Password is required",                       
        successMessage:"",
        showSuccessMessage:false,
        errorMessage:"",
        showErrorMessage:false,        
        isForgotPwd:false,                
    };
  },
  methods: {
    checkFormValidity() {
      console.log("checkFormValidity")      
      this.emailState = this.validateEmail(this.form.email)
      this.passwordState = this.validatePassword(this.form.pwd)
      this.phoneState = /^\d+$/.test(this.form.phone)  

      //registerUserMode:"login, register, setnewpassword, renewpasswordrequest"
      
      if (this.registerUserMode=='login' || this.registerUserMode=='setnewpassword' || this.registerUserMode=='renewpasswordrequest') {
        this.phoneState = true; 
      }
      if (this.registerUserMode=='renewpasswordrequest') {
        this.passwordState = true;
      }

      return (this.emailState && this.passwordState && this.phoneState);
    },
    validatePassword(aPwd) {
      if (aPwd.length>3) {
        return true
      } else if (aPwd.length>0) {
        this.invalidpasswordfeedback = "Password need to be a bit longer..."
        return false
      } else {
        this.invalidpasswordfeedback ="Password is required"
        return false        
      }      
    },
    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    resetModal() {
      this.form.email = "";
      this.form.pwd = ""
      this.form.phone = ""
      this.emailState = null;
      this.passwordState = null;
      this.phoneState = null;    
      this.resetMessages();
      //this.$emit("switchtype", "login");      
    },
    resetMessages() {
      this.successMessage = "";
      this.showSuccessMessage = false;
      this.showErrorMessage=false;
      this.errorMessage="";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing      
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleCancel() {
      this.resetMessages()
      console.log("handleCancel");      
      this.$emit("switchtype", "login");      
    },
    handleSubmit() {
      // Exit when the form isn't valid
      console.log("handle submit")
      this.resetMessages()
      console.log(JSON.stringify(this.form))
      if (!this.checkFormValidity()) {
        return;
      }
            
      if (this.registerUserMode == 'setnewpassword') {
        this.handleupdatePassword(this.form, this.renewPasswordtemporaryCode)
      }
            
      if (this.registerUserMode == 'renewpasswordrequest') {
        this.renewpasswordrequest(this.form)
      }
      if (this.registerUserMode =='login') {        
        this.handleLogin(this.form)
      } 
      if (this.registerUserMode =='register') {        
        this.handleRegister(this.form)
      }      
            
      // Hide the modal manually
      this.$nextTick(() => {
        // TODO this.$bvModal.hide("modal-register-user");
      });
    },
    switchtype() {
        console.log("swithc type methidod")
        this.resetModal()        
        this.$emit("switchtype");              
    },
    forgotpwd() {      
      this.resetModal();
      this.$emit("switchtype", "renewpasswordrequest");  

    },
    async handleRegister(form) {
        console.log("handleRegister. Form = "+JSON.stringify(form))            
        try {
           let response = await RestService.postNewUser(form);           
           VueCookies.set("lop-jwt",response.data,"1d");    
           console.log("Will EMIT lopuser" +form.email)
           this.$emit("add:lopuser", form.email);
           this.$emit("userloggedin", true);
           console.log("got response." +response)                
           this.showSuccessMessage = true;
           this.$emit("userloggedinEmit", true);
           this.successMessage="Great -You are registered!";           
          let myCookie = this.$cookies.get('lop-jwt');
          let response2 = await RestService.getTest(myCookie); 
          console.log("got response2." +response2)     
          //this.$bvModal.hide("modal-register-user");
        } catch (error) {
          console.log("ERROR RestService.postNewUser")   
          console.log(error);
          this.showErrorMessage=true;
          this.errorMessage="Username already registered."          
        }               
    },
    async handleupdatePassword(form, code) {
      console.log("handleupdatePassword. Form = "+JSON.stringify(form))
      try {
        let response = await RestService.updatePasswordForUser(form, code);           
        console.log("handleupdatePassword: " +response.data)      
        this.successMessage="Password updated. Please log in now!!";  
        this.showSuccessMessage = true;   
      } catch (error) {        
        console.log("Fail to handleupdatePassword")   
        console.log(error);       
        this.showErrorMessage=true;
        this.errorMessage="Ooops, something got wrong here!"          
      }              
    },

    async handleLogin(form) {      
      console.log("handleLogin. Form = "+JSON.stringify(form))    
      try {
        let response = await RestService.login(form);           
        VueCookies.set("lop-jwt",response.data,"1d");
        console.log("emit userlogged in")
        this.$emit("add:lopuser", form.email);    
        this.$emit("userloggedinEmit", true);
        this.successMessage="Great - You are logged in.";
        this.showSuccessMessage = true;

        let defaultTimeOut = 3500;
        this.timeoutId = setTimeout(() => {              
              this.$bvModal.hide("modal-register-user");              
          }, defaultTimeOut);       
        //this.isloggedin = true;
      } catch (error) {
        this.showErrorMessage=true;
        this.errorMessage="Could not log in you. Check username/password"                  
        console.log("Fail to login")   
        console.log(error);        
      }               
    },
    async renewpasswordrequest(form) {
      
      console.log("handleLogin. Form = "+JSON.stringify(form))    
      try {
        let response = await RestService.getpwdreset(form.email);  
        console.log("data="+response.data)     
        this.successMessage="Check your email - Password reset link is there!.";  
        this.showSuccessMessage = true;
      } catch (error) {
        console.log("Fail to renewpasswordrequest")           
        console.log(error);        
      }                
    },   

  },


  props: {
    renewPasswordExistingEmail:String,
    registerUserMode:String,
    renewPasswordtemporaryCode:String,
    isloggedin:Boolean,
  },
  computed: {
    oktitle() {      
      if (this.registerUserMode == 'setnewpassword') {
        return "Set password";
      }
      if (this.registerUserMode == 'renewpasswordrequest') {
        return "Send ResetLink";
      } else {
        return  this.registerUserMode =='login' ? "Login" : "Register"
      }
    },  
    disableOkButton() {
      if (this.isloggedin || this.showSuccessMessage) {
        return true;
      }
      return false;
    },
    canceltitle() {
      return  this.isloggedin ? "Close" : "Cancel"      
    },
    title() {
      if (this.registerUserMode == 'setnewpassword') {
        return "Set new password";
      }

      if (this.registerUserMode == 'renewpasswordrequest') {
        return "Forgot Password";
      } else {
        return  this.registerUserMode =='login' ? "Login to use TOUR features" : "Register to start use TOUR features"
      }
    }
  },
  mounted () {
    console.log("MOUNT REGISTER USER component")
  },

  watch: {
    'renewPasswordExistingEmail': function (newVal, oldVal){
         console.log("changing renewPasswordExistingEmail value OLD=" +oldVal + "  NEW="+ newVal)
         this.form.email = this.renewPasswordExistingEmail;
         //to work with changes in someOtherProp
    },
  },
};
</script>

<style scoped>

    .myregister {
        padding-top: 1rem;
    }
</style>