<template>
    <div>
        <b-modal id="modal-extras" @ok="cancelWheel" @cancel="cancelWheel"  @hidden="cancelWheel" ref="extrasmodal" title="Fortune Wheel">             
            <div class="winnercontainer">
                <div class="wheel-wrapper">
                    <div class="wheel-pointer" @click="onClickRotate">
                        SPIN
                    </div>
                    <div class="wheel-bg" :class="{freeze: freeze}" :style="`transform: rotate(${wheelDeg}deg)`">
                        <div class="prize-list">
                            <div class="prize-item-wrapper" v-for="(item,index) in prizeList" :key="index">
                                <div class="prize-item" :style="`transform: rotate(${(360/ prizeList.length) * index}deg)`">
                                    <div class="prize-name" :style="{ 'color': color[index], 'font-size': fontSizes[index], 'transform' :mytransform[index] }">
                                        {{ item }}
                                    </div>
                                    <!--div class="prize-icon">                                     
                                    </div-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
    
    
        </b-modal>
    
    </div>
</template>

    
<script>
export default {
    name: 'extras',

    props: {
        players: [],
    },

    mounted() {
    },

    data() {
        return {
            freeze: false,
            rolling: false,
            wheelDeg: 0,
            
            winner: "",
            winnerIndex: -1,
            

        };
    },
    computed: {
        prizeList() {
            let simplePlayers = []
            simplePlayers = this.players
            return simplePlayers
        },
        fontSizes() {
            let fontSizes = []
            for (let i = 0; i < this.players.length; i++) {
                if (this.winnerIndex == i) {
                    fontSizes[i] = '3rem'
                } else {
                    fontSizes[i] = '1rem'
                }
            }
            return fontSizes
        },
        color() {
            let colors = []
            for (let i = 0; i < this.players.length; i++) {
                if (this.winnerIndex == i) {
                    colors[i] = 'black'
                } else {
                    colors[i] = 'gray'

                }
            }
            return colors
        },
        mytransform() {
            let mytransforms = []
            for (let i = 0; i < this.players.length; i++) {
                if (this.winnerIndex == i) {
                    mytransforms[i] = 'rotate(0deg)'

                } else {
                    mytransforms[i] = 'rotate(-90deg)'

                }
            }
            return mytransforms
        }


    },
    methods: {
        cancelWheel() {
            this.winnerIndex = -1
            this.winner = ""
        },

        onClickRotate() {
            console.log("onClickRotate")
            if (this.rolling) {
                return;
            }
            this.winnerIndex = -1
            const result = Math.floor(Math.random() * this.prizeList.length);
            this.roll(result);
        },
        roll(result) {
            console.log("roll for result:" +result)
            this.winner = ""
            this.rolling = true;
            const { wheelDeg, prizeList } = this;
            this.wheelDeg =
                wheelDeg -
                wheelDeg % 360 +
                6 * 360 +
                (360 - 360 / prizeList.length * result);
            setTimeout(() => {
                this.rolling = false;
                this.winner = prizeList[result]
                this.winnerIndex = result                
            }, 5350);
        }
    },
    watch: {      
        winner: function(newValue, oldValue) {
            console.log("oldValue" + oldValue)
            console.log("newValue" + newValue)

        }
    }
};
</script>

<style scoped>
.winnercontainer {
    height: 300px;
}

.wheel-wrapper {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wheel-pointer {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    background: lightgray;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 60px;
    z-index: 10;
    cursor: pointer;
    
}

.wheel-pointer::after {
        content: "";
        position: absolute;
        top: -32px;
        left: 50%;
        border-width: 0 8px 40px;
        border-style: solid;
        border-color: transparent transparent lightgray;
        transform: translateX(-50%);
    }


.wheel-bg {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    overflow: hidden;
    transition: transform 5s ease-in-out;
    background: #89c2c2;
}

.wheel-bg.freeze {
    transition: none;
    background: red;

}

.prize-list {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
}

.prize-item-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
}

.prize-item {
    width: 100%;
    height: 100%;
    transform-origin: bottom;    
}
.prize-name {
    padding: 18px 0;    
}

</style>