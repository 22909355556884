<template>
  <div id="leaderboard-table">    
    <h1>{{title}}</h1>
    <table class="table">      
      <thead>                
        <th>#</th>
        <th class="whitespacenowrap">Name</th>    
        <th v-if="firstcolumntoshow>0"> <b-icon icon="three-dots" v-on:click="showallLines"></b-icon>  </th>                         
        
        <th v-for="(value, index) in modifieditems[0].scores" :key="index" style="padding-left: 8px;"> {{index+1 + firstcolumntoshow}}</th>
        <th style="padding-left: 8px;">Total</th>     
        <th style="padding-left: 8px;">Win</th>     
      </thead>
      <tbody>        
        <tr v-for="(item, index) in modifieditems" :key="item.id">      
          <td> {{index+1}} </td>    
          <td class="whitespacenowrap">{{ item.name }}              
            <b-icon-info-circle v-if="item.latearrival>0" variant="success" v-b-tooltip.hover="'Late arrival. Got default points the ' +item.latearrival +' rounds'">               
            </b-icon-info-circle> 
            <b-icon-info-circle v-if="item.earlyleaver>0" variant="danger" v-b-tooltip.hover="'Early leaver. Got default points after the ' +item.earlyleaver +' round'">                 
            </b-icon-info-circle> 
          </td>
          <td v-if="firstcolumntoshow>0" v-on:click="showallLines">... </td>
        
            <td v-for="(score, index2) in item.scores" :key="score.index" width="10%" style="white-space: nowrap"> 
              <span v-if="(currentlySelectedPlayerIndex==index && currentlySelectedScoreIndex ==index2)" >
                <b-form-input v-model="newScore" size="sm" style="padding: 0.1rem 0.1rem;"> </b-form-input> 
                <b-icon-check-circle variant="success" @click="saveNewScore(index, index2, item.id)" style="margin-right: 10px;"></b-icon-check-circle>
                <b-icon-x-circle variant="danger" @click="cancelNewScore()"></b-icon-x-circle>                
              </span>

              <span v-else @click="setEditMode(index, index2)" :style="{ 'background-color': getColorOfBackground(index,index2),border: getBorder(index,index2) ,padding:'3px' , 'border-radius':'5px' }" >
                {{score}}
              </span>
            </td>
          <td v-if="item.totalscore" width="10%">
            <span class="mybadge focus1">{{ item.totalscore }}</span>
          </td>
          <td v-else width="10%">
            <span class="mybadge focus1">0</span>
          </td>

          <td v-if="item.totalwins" width="10%">
            <span class="mybadge focus2">{{ item.totalwins }}</span>
          </td>
          <td v-else width="10%">
            <span class="mybadge focus2">0</span>
          </td>

        </tr>
      </tbody>
    </table>    
  </div>
</template>

<script>
export default {
  name: "leaderboard-table",
  data() {
    return {   
      firstcolumntoshow :0,           
      maxnumberofcolumns:0,    
      numberofvisiblecolumns:0,      
      componentKey:0,      
      newScore:0,   
      isEditing:false,
      currentlySelectedPlayerIndex:-1,
      currentlySelectedScoreIndex:-1,
      timeoutId:-1,
      hidingSomeColumns:-1,      
    };
  },
  props: {
    items: Array,  
    sortby:String,  
    title: String,   
    defaultnumberofvisiblecolumns : Number,        
    displayreactor:  Number ,   
  },
  
  methods: {
    showallLines: function () {            
      if (this.numberofvisiblecolumns < this.maxnumberofcolumns) {
        this.numberofvisiblecolumns = this.maxnumberofcolumns
        this.firstcolumntoshow = 0;
      } else {
        this.numberofvisiblecolumns = this.defaultnumberofvisiblecolumns;        
      }           
      let defaultTimeOut = 6000;
      this.timeoutId = setTimeout(() => {
            this.numberofvisiblecolumns = this.defaultnumberofvisiblecolumns;
        }, defaultTimeOut);       
    },    
    setEditMode(playerIndex, scoreIndex) {      
      this.isEditing = true;
      if (this.hidingSomeColumns >0) {
        clearTimeout(this.timeoutId);
      }
      this.currentlySelectedPlayerIndex=playerIndex
      this.currentlySelectedScoreIndex=scoreIndex
      this.newScore = this.items[playerIndex].scores[scoreIndex + this.firstcolumntoshow]
    },
    saveNewScore(playerIndex, roundIndex, playerId) {
      roundIndex = this.firstcolumntoshow + roundIndex
      //console.log("saveNewScore. the playerIndex=" +playerIndex  +"roundIndex="+roundIndex  +" playerId"+playerId +" newScor"+this.newScore)
      let newScoreObject = {playerId:playerId, roundIndex:roundIndex, newScore:this.newScore}
      this.cancelNewScore();
      this.$emit("newScore", newScoreObject);
    },
    cancelNewScore() {
      console.log("Cancel Score");
      this.isEditing = false;      
      this.timeoutId = setTimeout(() => {
            this.numberofvisiblecolumns = this.defaultnumberofvisiblecolumns;
        }, 2700); 
      this.currentlySelectedPlayerIndex=-1
      this.currentlySelectedScoreIndex=-1
    },
    getColorOfBackground(index, round) {
      let colorIndex = round+this.firstcolumntoshow
      let teamcolor = this.items[index].teamcolor[colorIndex]
      if (Number.isInteger(teamcolor) ) {
        if (teamcolor == -1) {
          return ""
        }
      } else if (typeof teamcolor == "string"){
        return teamcolor
      } 
      return "";
    },

    getBorder(index, round) { 
      
      let roundIndex = round+this.firstcolumntoshow
      let playerPoints = this.items[index].scores[roundIndex]
      let maxPoint = this.items[index].maxscores[roundIndex]
      let wasEven = false
      if (2*playerPoints == maxPoint) {
        wasEven = true
      }      
      let didWin = this.items[index].wins[roundIndex]
      let didPlay =  typeof this.items[index].teamcolor[roundIndex] == "string"
      if (wasEven && didPlay) {
        return '2px dashed red'
      }
      else if (didWin == 1) {
        return '2px solid green'
      } else if (didWin == 0 && didPlay){
        return '2px solid red'
      } else {
        return ''
      }
      
    },
   
  },
  computed: {
    modifieditems: function () {
      // console.log("RUNNING modifieditems - -  - - - - - -this.numberofvisiblecolumns=" +this.numberofvisiblecolumns)      
      let myNewItems = JSON.parse(JSON.stringify(this.items));         
      myNewItems.forEach(player => {
        if (typeof player.scores !== 'undefined' && player.scores.length > this.numberofvisiblecolumns) {          
          this.maxnumberofcolumns = player.scores.length;
          this.firstcolumntoshow = player.scores.length - this.numberofvisiblecolumns;
          this.hidingSomeColumns = this.maxnumberofcolumns - this.numberofvisiblecolumns          
          player.scores = player.scores.slice(player.scores.length -this.numberofvisiblecolumns)
        } else {
          this.firstcolumntoshow = 0; 
        }
      })      
      return myNewItems;
    }

  },
  mounted () {
    this.numberofvisiblecolumns = this.defaultnumberofvisiblecolumns;
  },

  
  watch: {
    defaultnumberofvisiblecolumns: function (newVal) {      
      this.numberofvisiblecolumns = newVal;
    },
  },

};
</script>

<style scoped>

td {
  padding: 0.2rem;
}
.whitespacenowrap {
  max-width:100%; 
  white-space:nowrap;
}

.focus1 {
  background-color: #35D646;
}

.focus2 {
  background-color: #ADF7B5;
}

.idleMarkup {
  background-color: lightgray;
}


.mybadge {
  /* color: white; */
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}
</style>