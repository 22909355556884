<template>
  <div id="modal-component">
    
    <transition name="modal">
      <div class="modal-mask">                 
        <div class="modal-wrapper">          
          <div class="modal-container">
            <div class="button-container">              
              <b-icon icon="x-circle" font-scale="2" @click="close()"></b-icon>      
            </div>
            <div class="number-container">            
              <div v-for="number in scoreset" :key="number">              
                <button class="roundbutton" @click="handlescore(number)">
                  {{ number }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "modal-component",
  data() {
    return {      
    };
  },
  props: {
    scoreset: Array,
  },

  methods: {
    handlescore(number) {
      //console.log("MODALCOMPONENTN handlescore.  number= " + number);
      this.$emit("submit:matchscore", number);
      return "";
    },
    close() {      
      this.$emit("close",);
      return;
    },
  },

computed: {    
     myDisplay : function() {
       console.log("MODAL see if number is set " +this.number)
        if (typeof this.number == 'undefined' || this.number == null) {
            return "?"    
        }
        return this.number;
      }
  },

};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction:column;
}
.number-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.button-container {
  align-self: flex-end;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
.roundbutton {
  width: 3.2rem;
  background-color: lightgray;
  border: none;
  border: 2px solid black;
  color: black;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  margin: 0.2rem;
}
.reportbutton {
  background-color: red; 
  border: 2px solid black;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>