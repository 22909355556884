<template>
    <div>
    
        <b-modal id="modal-rank" ref="rankmodal" title="Players Rank">
            <p class="my-4">All player that have played any Americano or Mexicano at your court is compared, and we created a ranking for you. You can get ranking from different timeperiod 3 Month, 6 Month, 12 Month.</p>
    
            <b-button v-on:click="getRank3()" variant="primary" size="sm" class="mx-1">Rank 3M</b-button>
            <b-button v-on:click="getRank6()" variant="primary" size="sm" class="mx-1">Rank 6M</b-button>
            <b-button v-on:click="getRank12()" variant="primary" size="sm" class="mx-1">Rank 12M</b-button>            

            <div v-show="loading" class="text-center mt-5">
              <b-spinner variant="primary"></b-spinner>
            </div>
    
            <div class="flex-container">
                <div v-for="(group, index) in groupInfo" :key="index" v-on:click="showRankForGroup(group.key)" class="flex-item">
                    <b-card border-variant="primary" :header-text-variant="getheadertextvariant(group.key)" text-variant="primary" :header-bg-variant="getheaderbgvariant(group.key)" :header="group.name" class="flex-item">
                        <b-card-text>
                            <ul v-for="(topscorer, index) in group.topscorer" :key="index">
                                <li>#{{index+1}} - {{topscorer.name}}</li>
                            </ul>
                        </b-card-text>
                    </b-card>
                </div>
            </div>
    
    
    
            <div>
                <b-table striped hover :items="formattedRank()" :fields="fields" label-sort-asc="" label-sort-desc="" label-sort-clear="">
    
                </b-table>
            </div>
        </b-modal>
    </div>
</template>
     

<script>
import RestService from '@/services/RestService.js'


export default {
    name: "rank",


    data() {
        return {
            loading:false,
            selected: 0,
            rankedplayers: [],
            groups: [],
            groupInfo: [],
            fields: [{ key: 'position', label: 'Rank#', sortable: true }, { key: 'name', sortable: true }, { key: 'ratio', label: 'RankRatio', sortable: true }]
        };
    },
    methods: {
        showRankForGroup(key) {            
            this.selected = key
            this.rankedplayers = this.groups[key]
        },
        async getRank(history) {
            this.loading = true;            
            try {
                let mytoken = this.$cookies.get('lop-jwt');
                let responseMyRank = await RestService.getRank(mytoken, history)
                this.groups = responseMyRank.data
                this.groupInfo = this.sortAndExtendGroups(this.groups)                
                if (typeof this.groupInfo[0] !== 'undefined') {
                    this.selected = this.groupInfo[0].key
                    this.showRankForGroup(this.selected)
                }
            } catch (error) {
                console.log("Can not load Rankings")
            }
            this.loading = false;
        },
        sortAndExtendGroups(groupsOfPlayers) {
            let groups = []
            for (let i = 0; i < groupsOfPlayers.length; i++) {
                let groupsize = groupsOfPlayers[i].length                
                let myName = ""
                let o = { name: myName, key: i }
                o.topscorer = []
                o.groupsize = groupsize
                let swhoPlayerNbr = 3
                if (groupsize < 4) {
                    swhoPlayerNbr = groupsize
                }
                for (let k = 0; k < swhoPlayerNbr; k++) {
                    o.topscorer.push(groupsOfPlayers[i][k])
                }
                groups.push(o)
            }
            groups.sort((a, b) => b.groupsize - a.groupsize);
            for (let i = 0; i < groups.length; i++) {
                let myName = "Group " + (i + 1) + ": (" + groups[i].groupsize + ") "
                groups[i].name = myName
            }
            return groups;
        },


        formattedRank() {
            for (let i = 0; i < this.rankedplayers.length; i++) {
                this.rankedplayers[i].ratio = parseFloat(this.rankedplayers[i].ratio).toFixed(2)
                this.rankedplayers[i].position = i + 1
            }
            return this.rankedplayers
        },

        getGroups() {
            let group = []
            for (let i = 0; i < this.rankedplayers.length; i++) {
                let o = {}
                o.key = i
                o.name = "Group " + this.rankedplayers[i].length
                group.push(o)
            }
        },

        getheaderbgvariant(index) {
            let color = 'white'
            if (index == this.selected) {
                color = 'primary'
            }
            return color
        },
        getheadertextvariant(index) {
            let color = 'primary'
            if (index == this.selected) {
                color = 'white'
            }
            return color
        },
        getRank3() {
            const today = new Date();
            const history = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000);
            this.getRank(history)
        },
        getRank6() {
            const today = new Date();
            const history = new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000);
            this.getRank(history)
        },
        getRank12() {
            const today = new Date();
            const history = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);
            this.getRank(history)
        },
        getRank24() {
            const today = new Date();
            const history = new Date(today.getTime() - 2 * 365 * 24 * 60 * 60 * 1000);
            this.getRank(history)
        }


    },


    props: {
        email: String,


    },
    computed: {
        myGroupsNotUsed: function() {
            let groups = []
            return groups;
        }


    },
    mounted() {
        console.log("MOUNTING REGISTER RANK")
    },

    watch: {},
};
</script>

<style scoped>
.createnewtourlabel {
    margin-top: 3rem;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}

.card-body {
    padding: 0.5rem 0.5rem;
}

.flex-item {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>

