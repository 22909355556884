<template>
  <div style="margin-bottom:2rem">    
    <div class="courtmatchcontainer" :style="courtmatchcontainerstyleObject">            
      <div v-for="match in round.matches" :key="match.id" class="courtmatch" :style="courtmatchstyleObject"  >
        <div class="courtlabel" v-bind:style="{ 'background-color': match.court.color }">
          <h4>{{ match.court.name }} </h4>
        </div>
        <div class="courtyard" v-bind:style="{ 'background-color': match.court.color }">
          <div v-for="team in match.teams" :key="team.id" class="courthalf" >                            
            <team-display
              :myScore="team.score"
              :player1="theName(team.player2, team.player1).player1"
              :player2="theName(team.player2, team.player1).player2"
              :id="team.id"
              :matchid="match.id"
              :maxscore="round.maxscore"
              :myreactor="displayreactor"
              :iscomplete="round.iscomplete"
              :showPositionAndTrend="showPositionAndTrend"              
              @submit:points="submitPoints2"
            >
            </team-display>            
          </div>
        </div>
      </div>
    </div>
  

    <div v-if="typeof round.idleplayers !== 'undefined' && round.idleplayers.length>0 && round.type =='groupplay'">
      <b-icon icon="people-fill" variant="dark" font-scale="2"></b-icon>
      <span v-for="idleplayer in round.idleplayers" :key="idleplayer.id"  >                
        <b-badge variant="warning" style="color:black;background-color:lightgray;border: 2px solid black;font-size:1.2rem;margin:0.2rem 0.5rem 0.2rem 0.5rem;font-weight: 500;">{{idleplayer.name}}</b-badge>
      </span>      
    </div>
            
    <div v-if="round.type === 'groupplay'" class="buttoncontainer">
      <b-button v-if="round.type === 'groupplay' && showCompleteRoundbutton" v-on:click="completeround" :disabled="completeroundbuttondisabled" variant="primary" class="americanobutton">
        Complete Round {{ roundindex + 1 }}
      </b-button >    
      <b-button v-if="!round.iscomplete && usertype=='master' && roundindex>0" v-on:click="cancelgroupplay" variant="outline-danger" class="americanobutton rightbutton">
        <b-icon-x-circle variant="danger"></b-icon-x-circle>
        Cancel round
      </b-button >      
    </div>  


    <div v-if="round.type === 'finalplay'" class="buttoncontainer">
      <b-button  v-on:click="completefinal" :disabled="completeroundbuttondisabled" variant="primary" class="americanobutton leftbutton">      
        Complete Final 
      </b-button >      
      <b-button v-if="!round.iscomplete && usertype=='master'" v-on:click="cancelfinal" variant="outline-danger" class="americanobutton rightbutton">
        <b-icon-x-circle variant="danger"></b-icon-x-circle>
        Cancel Final  
      </b-button >      
    </div>


  
  </div>
</template>

<script>
import TeamDisplay from "./TeamDisplay.vue";
export default {
  name: "round-display",
  components: {
    TeamDisplay,
  },
  props: {    
    round: Object,
    roundindex: Number,    
    allscorereported: Boolean,    
    displayreactor: Number,    
    width:Number,
    usertype: String,
    iswaitingforDB: Boolean,
    showPositionAndTrend : Boolean,
    showCompleteRoundbutton : Boolean,
  },
  data() {
    return {
      points: 0,  
    };
  },
  methods: {
    submitPoints2(teamId, points, matchid) {
      this.points = points;
      this.$emit("report:points",matchid, teamId, points, this.roundindex, true);
    },
    completeround() {
      console.log("in completeround");
      this.$emit("completeround", this.roundindex);
    },
    completefinal() {
      console.log("in completefinal");
      this.$emit("completefinal", this.roundindex);
    },
    cancelfinal() {
      console.log("in cancelfinal");
      this.$emit("cancelfinal", this.roundindex);
    },
    cancelgroupplay() {
      console.log("in cancelgroupplay");
      this.$emit("cancelgroupplay", this.roundindex);
    },
    theName: function(player2, player1) {      
      let res = {};
      res.player1 = JSON.parse(JSON.stringify(player1))
      res.player2 = JSON.parse(JSON.stringify(player2))
      if (player2 == null) {
        let temp_p1 = JSON.parse(JSON.stringify(player1))
        res.player2 = temp_p1        
        let parts = temp_p1.name.split(" & ")
        if (Array.isArray(parts) && parts.length == 2) {
          res.player1.name = parts[0];
          res.player2.name = parts[1];
        } else {
          res.player2.name = ' - ';
        }                
      } 
      return res;
    },

  },
  computed: {
    completeroundbuttondisabled: function () {      
      if (!this.allscorereported || this.round.iscomplete || this.iswaitingforDB) {
        return true;
      } else {
        return false;
      }       
    },
    courtmatchstyleObject()  {
      let myMargin = '0rem';
      let flexdirection = 'column';
      if (this.width> 700) {
        myMargin = "1rem"        
      } else {
        myMargin = "2rem 0rem 0rem 0rem"
      }
      return {
        'margin':myMargin,
        'flex-direction': flexdirection,
      }
    },
    courtmatchcontainerstyleObject() {
      if (this.width> 700) {
        return {        
          'flex-wrap': 'wrap', 
          'flex-direction': 'row',             
        }
      } else {
        return {        
          'flex-wrap': 'nowrap', 
          'flex-direction': 'column',                  
        }
      }
    }


  },
};
</script>

<style scoped>

/* NOTE: cope of css from app for hte buttoncontainer  */
.buttoncontainer {
  display: flex;
  justify-content: space-between;
}
.leftbutton {
  margin-right:1rem;
}

.rightbutton {
  margin-left:1rem;
}

.courtmatchcontainer {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  /* flex-wrap: wrap ; */
  align-content: space-around;
}

.courtmatch {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex-grow: 1;
  min-width: 300px;
  max-width: 700px;  
}



.courtlabel {  
  align-self: center;
  width: 38%;
  white-space: nowrap;
  border: 3px solid black;
  border-radius: 15px 15px 0px 0px;  
  border-bottom: 0px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding-top: 0.5rem;
}

.courtyard {
  display: flex;
  flex-direction: row;  
}

.courthalf {
  border: 2px solid black;
  display: flex;
  width: 50%;
  justify-content: center;
  padding:0.5rem
}


.side {
  height: 1.4rem;
  width: 20%;
  background-color: whitesmoke;
  margin: 0.4rem;
}
</style>